import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const AnimateFade = (props) => {
    return (
        <motion.div
            {...props}
            initial={{ opacity: 1 }}
            animate={{ opacity: props.show ? 1 : 0 }}
            transition={{ duration: 1 }}
        >
            {props.children}
        </motion.div>
    );
};

AnimateFade.propTypes = {
    show: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
};

export default AnimateFade;
