import React, { useRef, useState, useEffect } from "react";

import { isNil } from "lodash";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import useLocalStorage from "../../../../hooks/useLocalStorage";
import ScaleLoader from "react-spinners/ScaleLoader";

const V3FlipSwiper = ({ property }) => {
    const images = property?.image_urls;
    const swiperRef = useRef(null);
    const [preferredVersion] = useLocalStorage("live-variant-preferred-version");
    const [loadedImages, setLoadedImages] = useState(Array(images.length).fill(false));
    const isLiveVariant =
        (preferredVersion && preferredVersion !== "classic") || window.location.pathname === "/";
    const isV3Flip = preferredVersion === "flip";

    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {
            Toolbar: false,
            Thumbs: false,
        });

        return () => {
            Fancybox.unbind("[data-fancybox]");
        };
    }, []);

    const handleImageLoad = (index) => {
        setLoadedImages((prev) => {
            const newLoaded = [...prev];
            newLoaded[index] = true;
            return newLoaded;
        });
    };

    return (
        <Swiper
            ref={swiperRef}
            modules={[Pagination, EffectFade, Navigation]}
            loop
            pagination={{
                el: ".swiper-pagination",
                type: "fraction",
                renderFraction: function (currentClass, totalClass) {
                    if (images.length < 5 && !isLiveVariant) {
                        return `<div class="pagination-fraction-wrapper px-1 insufficient-image-text" data-cy="pagination">
                            <span><i>MLS rules require us to keep the rest of these photos top secret</i></span>
                        </div>`;
                    }

                    return `<div class="pagination-fraction-wrapper px-1 ${isV3Flip ? "tw-h-[22px]" : ""}" data-cy="pagination">
                                <span class="${currentClass}"></span> of <span class="${totalClass}"></span>
                            </div>`;
                },
            }}
            navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }}
            data-cy="property-image-swiper"
            speed="400"
            effect="fade"
            className="property-image-swiper !tw-h-[33svh] md:!tw-h-[35svh] lg:!tw-h-[33svh]"
            onSwiper={(swiper) => {
                // Dynamically add classes to the pagination element after rendering
                const paginationEl = swiper.el.querySelector(".swiper-pagination");
                if (paginationEl && isV3Flip) {
                    paginationEl.classList.add(
                        "!tw-top-4",
                        "!tw-justify-start",
                        "!tw-pl-2",
                        "md:!tw-pl-24",
                        "lg:!tw-pl-[25rem]",
                        "!tw-z-[4]",
                        "!tw-bottom-auto"
                    );
                }
            }}
        >
            {images.map((image, index) => (
                <SwiperSlide className="swiper-slide" key={index}>
                    <div
                        className="image-container tw-mx-0"
                        data-fancybox={`gallery-${property.id}`}
                        data-src={image}
                    >
                        {(!loadedImages[index] || isNil(loadedImages[index])) && (
                            <div className="tw-flex !tw-justify-center !tw-items-center tw-h-full">
                                <ScaleLoader color="#63c19f" />
                            </div>
                        )}
                        <img
                            className="!tw-w-full md:!tw-w-auto"
                            src={image}
                            alt={`Slide ${index}`}
                            onLoad={() => handleImageLoad(index)}
                            style={{ display: loadedImages[index] ? "block" : "none" }}
                        />
                    </div>
                </SwiperSlide>
            ))}
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
            <div className="swiper-pagination"></div>
        </Swiper>
    );
};

export default V3FlipSwiper;
