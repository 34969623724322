import React, { useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import styles from "./leaderboard.module.scss";
import "swiper/css";

import { capitalize } from "lodash";

import ExpandArrowIcon from "../../assets/icons-v2/expand_arrow.png";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import Classic from "./components/Classic";
import Puzzle from "./components/Puzzle";

import { LIVE_VARIANT_VERSIONS } from "../../constants/live-variant-versions";

import { useUser } from "../../context/UserContext";

const Leaderboard = () => {
    const { userState, setUserState } = useUser();
    const tabValue = userState?.leaderboardSelectedVersionIndex ?? 1;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const versions = Object.values(LIVE_VARIANT_VERSIONS);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelect = (event, newTabValue) => {
        setUserState({ ...userState, leaderboardSelectedVersionIndex: newTabValue });
        handleClose();
    };

    return (
        <div className={styles.container + " !tw-px-4"}>
            <div className="tw-flex tw-gap-1 tw-mb-[-10px]">
                <h3 className="leaderboard-title">Leaderboard:</h3>
                <div
                    className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-cursor-pointer"
                    onClick={handleDropdown}
                >
                    <h3 className="leaderboard-title">{capitalize(versions[tabValue]?.url)}</h3>
                    <img className="tw-mb-[.5em]" src={ExpandArrowIcon} alt="expand-arrow-icon" />
                </div>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{ mt: -1 }}
                >
                    {versions.map((version, index) => (
                        <MenuItem
                            onClick={(e) => handleSelect(e, index)}
                            selected={tabValue === index}
                            disabled={version?.disableInProfile}
                        >
                            {version?.disableInProfile
                                ? `${capitalize(version.url)} (Coming soon)`
                                : capitalize(version.url)}
                        </MenuItem>
                    ))}
                </Menu>
            </div>

            <CustomTabPanel value={tabValue} index={1}>
                <Classic />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={0}>
                <Puzzle version={versions[tabValue]} />
            </CustomTabPanel>
        </div>
    );
};

export default Leaderboard;
