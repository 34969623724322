import { useAuth0 } from "@auth0/auth0-react";

/**
 * A custom React hook for handling user logout with Auth0.
 *
 * @returns {Object} An object containing the `logoutUser` function.
 */
const useLogout = () => {
    const { logout } = useAuth0();

    /**
     * Logs the user out using Auth0 and redirects the window's origin
     */
    const logoutUser = () => {
        // Only keep essential data that should persist across sessions
        const keysToKeep = ["archived-properties"];

        // Clear all data except essential keys
        Object.keys(localStorage).forEach((key) => {
            if (!keysToKeep.includes(key)) {
                localStorage.removeItem(key);
            }
        });

        // Clear session storage
        window.sessionStorage.clear();

        const version = window.location.pathname.split("/")[1] ?? "classic";

        // First, log out from Google
        const googleLogoutURL = "https://accounts.google.com/Logout";
        fetch(googleLogoutURL, { mode: "no-cors" }); // Trigger Google logout

        setTimeout(() => {
            logout({
                logoutParams: {
                    returnTo: window.location.origin + `/${version}`,
                    logoutParams: {
                        federated: true,
                        clearSession: true,
                    },
                },
            });
        }, 1500); // Delay to ensure Google logs out first
    };

    return { logoutUser };
};

export default useLogout;
