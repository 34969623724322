import axios from "axios";

/**
 * Retrieves the notification settings for a specific user.
 *
 * @param {string} userId - The unique identifier of the user.
 * @param {boolean} [fetchAll=false] - Whether to fetch all notification settings or just one.
 * @returns {Promise<Object>} - A Promise that resolves to an object representing the user's notification settings.
 *
 */
export const getUserNotificationSettings = async (userId, fetchAll = false) => {
    return (
        await axios.get(`/api/notifications/user/${userId}`, {
            params: { fetchAll },
        })
    ).data;
};
