/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./profile-drawer.scss";
import { useUser } from "../../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import useLogout from "../../../hooks/useLogout";
import toast from "react-hot-toast";
import { syncUser } from "../../../services/UserService";
import { getUserNotificationSettings } from "../../../services/NotificationService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Switch from "../switch/switch";
import { isEmpty, capitalize } from "lodash";
import { Link } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import ClipLoader from "react-spinners/ClipLoader";
import { X } from "react-bootstrap-icons";
import { Check } from "react-bootstrap-icons";
import NotificationSetting from "./notification-setting";
import { clsx } from "clsx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CustomTabPanel from "../CustomTabPanel";
import { LIVE_VARIANT_VERSIONS } from "../../../constants/live-variant-versions";

function a11yProps(index, total, disabled) {
    return {
        id: `simple-tab-${index}`,
        disabled,
        sx: {
            textTransform: "none",
            minWidth: 80,
            padding: "8px 12px",
            position: "relative",
            color: disabled ? "#aaa" : "#100F13", // Greyed out for disabled tabs
            "&.Mui-selected": {
                color: "#3B166A", // Custom active tab text color
                fontWeight: "bold",
            },
            "&:not(:first-of-type)::before":
                index > 0 && index < total - 1
                    ? {
                          content: '""',
                          position: "absolute",
                          left: 0,
                          top: "50%",
                          transform: "translateY(-50%)",
                          height: "60%",
                          width: "1px",
                          backgroundColor: "#ccc",
                      }
                    : undefined,
        },
    };
}

const ProfileDrawer = (props) => {
    const { userState, isInvalidLogin, setInvalidLogin, fetchUserData } = useUser();
    const { user, isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
    const { logoutUser } = useLogout();
    const queryClient = useQueryClient();
    const [isEditingNickname, setIsEditingNickname] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [tabValue, setTabValue] = React.useState(0);

    // Add React Query hook for notification settings with improved error and loading states
    const {
        data: notificationSettings,
        isLoading: isLoadingSettings,
        isFetching: isFetchingSettings,
        error: settingsError,
        refetch: refetchSettings,
    } = useQuery({
        queryKey: ["user-notif-settings", userState?.id],
        queryFn: async () => {
            const response = await getUserNotificationSettings(userState?.id, true);
            // Ensure we always return an array of notification settings
            if (!response) return [];
            if (!Array.isArray(response.message)) {
                return response.message ? [response.message] : [];
            }
            return response.message;
        },
        enabled: !!userState?.id && props.isOpen, // Only fetch when drawer is open
        staleTime: 0, // Always fetch fresh data when drawer opens
        cacheTime: 1000 * 60 * 5,
        retry: 2,
    });

    const [profileValues, setProfileValues] = useState({
        data: {
            nickname: "",
            email: "",
            showScoreLeaderboard: false,
            notifyDailyProperty: false,
            notificationSetting: [],
            isPro: false,
            showStartupModal: false,
            useEmailAccountPicture: true,
        },
        submit: false,
    });

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    // Handles updating of user values
    const handleUpdateUser = useCallback(async () => {
        try {
            if (!profileValues.submit) return;

            toast.loading("Updating profile...", { id: "update-profile" });
            setIsSubmitting(true);

            // Create payload with safe access to current_location
            const payload = {
                id: userState?.id,
                nickname: profileValues.data.nickname,
                email: profileValues.data.email,
                show_score_leaderboard: profileValues.data.showScoreLeaderboard,
                notify_daily_property: profileValues.data.notifyDailyProperty,
                notification_settings: profileValues.data.notificationSetting,
                is_pro: profileValues.data.isPro,
                show_startup_modal: profileValues.data.showStartupModal,
                use_email_account_picture: profileValues.data.useEmailAccountPicture,
            };

            // Only add current_location if it exists
            if (userState?.current_location) {
                payload.current_location = userState.current_location;
            }

            await syncUser(payload);

            // Invalidate both user data and notification settings queries
            // This ensures React Query knows the cached data is stale and will fetch fresh data
            // when the component re-renders or when the query is next used
            await Promise.all([
                fetchUserData(userState?.id),
                queryClient.invalidateQueries(["user-notif-settings", userState?.id]),
            ]);

            if (isEditingNickname) setIsEditingNickname(false);

            setProfileValues((prev) => ({ ...prev, submit: false }));

            // This adds a bit of leeway for the animation to be visible for the toast message
            setTimeout(() => {
                toast.success("Profile successfully updated!", { id: "update-profile" });
                setIsSubmitting(false);
            }, 250);
        } catch (error) {
            // Can't reproduce error on changing nickname
            // Added this to explicitly display the actual error object for PM-740
            toast.error(error?.response?.data?.message ?? "Oopps...something went wrong.", {
                id: "update-profile",
            });
            setIsSubmitting(false);
        }
    }, [
        profileValues.submit,
        profileValues.data,
        userState?.id,
        userState?.current_location,
        isEditingNickname,
        fetchUserData,
        queryClient,
    ]);

    /**
     * React hook to handle invalid login scenarios.
     * If the user is authenticated but has an invalid login (`isInvalidLogin` is true), it logs an error message and initiates a logout process after a 3-second delay.
     *
     * @param {boolean} isAuthenticated - Indicates if the user is authenticated (logged in).
     * @param {boolean} isInvalidLogin - Indicates if the login is invalid (user object in the User context is missing or invalid).
     * @param {Function} logout - The function to initiate the logout process.
     */
    useMemo(() => {
        if (isAuthenticated && isInvalidLogin) {
            console.error("You don't have an user object in your User context! Logging out...");

            setTimeout(() => {
                setInvalidLogin(false);
                logoutUser();
            }, 3000);
        }
    }, [isInvalidLogin]); // eslint-disable-line react-hooks/exhaustive-deps

    // Executes user update when submit is true
    useEffect(() => {
        handleUpdateUser();
    }, [handleUpdateUser]);

    // Update the useEffect for profileValues to handle notification settings properly
    useEffect(() => {
        setProfileValues((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                nickname: userState?.nickname ?? user?.nickname ?? "No nickname",
                email: userState?.email_address ?? "No email",
                showScoreLeaderboard: userState?.show_score_leaderboard ?? false,
                notifyDailyProperty: userState?.notify_daily_property ?? false,
                notificationSetting: notificationSettings,
                isPro: userState?.is_pro,
                showStartupModal: userState?.show_startup_modal,
                useEmailAccountPicture: userState?.use_email_account_picture ?? false,
            },
        }));
    }, [
        user?.nickname,
        userState?.email_address,
        userState?.is_pro,
        userState?.nickname,
        userState?.notify_daily_property,
        userState?.show_score_leaderboard,
        userState?.show_startup_modal,
        userState?.use_email_account_picture,
        notificationSettings,
    ]);

    return (
        isAuthenticated &&
        !isEmpty(userState?.id) && (
            <>
                {props.isOpen && (
                    <div
                        onClick={props.onClose}
                        className={clsx("profile-backdrop", props.isOpen && "open")}
                    />
                )}
                <div className={clsx("profile-drawer", props.isOpen && "open")}>
                    <div className="container profile py-4 profile-container">
                        <div className="border-b profile-section-header pb-2 mb-3 d-flex justify-content-between align-items-center flex-wrap gap-2">
                            <h3 className="app-text-title profile-section-title px-0 mb-0">Profile</h3>
                            <Link onClick={props.onClose} className="close-btn">
                                <GrClose fontSize={18} />
                            </Link>
                        </div>

                        {/* Show loader if either Auth0 or settings are loading */}
                        {isAuth0Loading || isLoadingSettings || isFetchingSettings ? (
                            <div className="d-flex justify-content-center align-items-center py-4">
                                <ClipLoader
                                    size={24}
                                    aria-label="Loading"
                                    data-testid="loader"
                                    color="#3B166A"
                                />
                            </div>
                        ) : (
                            <>
                                <div className="section-group pb-2 mb-3 border-b">
                                    <p className="fw-bold mb-2 section-label">Nickname</p>
                                    {!isEditingNickname ? (
                                        <div className="d-flex justify-content-between flex-wrap gap-2">
                                            <p>{userState.nickname ?? user.nickname}</p>
                                            <button
                                                className="app-text-link"
                                                onClick={() => setIsEditingNickname(true)}
                                            >
                                                Change nickname
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="profile-setting-container flex-grow-2">
                                            <form className="profile-form">
                                                <input
                                                    type="text"
                                                    placeholder="Enter nickname"
                                                    value={profileValues.data.nickname}
                                                    onChange={(e) =>
                                                        setProfileValues((prev) => ({
                                                            ...prev,
                                                            data: { ...prev.data, nickname: e.target.value },
                                                        }))
                                                    }
                                                />
                                                <div className="btn-group">
                                                    <button
                                                        type="button"
                                                        className="undo-btn"
                                                        onClick={() => {
                                                            setIsEditingNickname(false);
                                                            setProfileValues((prev) => ({
                                                                ...prev,
                                                                data: {
                                                                    ...prev.data,
                                                                    nickname:
                                                                        userState.nickname ??
                                                                        user.nickname ??
                                                                        "No nickname",
                                                                },
                                                            }));
                                                        }}
                                                    >
                                                        <X />
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            setProfileValues((prev) => ({
                                                                ...prev,
                                                                submit: true,
                                                            }))
                                                        }
                                                        type="button"
                                                        className="save-btn"
                                                    >
                                                        {isSubmitting ? (
                                                            <ClipLoader
                                                                size={12}
                                                                aria-label="Loading Spinner"
                                                                data-testid="loader"
                                                                color="white"
                                                            />
                                                        ) : (
                                                            <Check />
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </div>

                                {/* Commented out for now. Might be used in the future
                                
                                <div className="section-group pb-2 mb-3 border-b">
                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                        <p className="fw-bold mb-2 section-label">Use email account profile picture</p>
                                        <Switch
                                            checked={profileValues?.data?.useEmailAccountPicture}
                                            onChange={(e) => {
                                                setProfileValues((prev) => ({
                                                    ...prev,
                                                    submit: true,
                                                    data: {
                                                        ...prev.data,
                                                        useEmailAccountPicture: !prev.data.useEmailAccountPicture,
                                                    },
                                                }));
                                            }}
                                        />
                                    </div>
                                </div> */}

                                <div className="section-group pb-2 mb-3">
                                    <p className="fw-bold mb-2 section-label">Email</p>
                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                        <p>{userState.email_address}</p>
                                    </div>
                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                        <span className="logout" onClick={() => logoutUser()}>
                                            Log out
                                        </span>
                                    </div>
                                </div>

                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <Tabs
                                            value={tabValue}
                                            onChange={handleChangeTab}
                                            TabIndicatorProps={{
                                                style: { backgroundColor: "#3B166A", maxWidth: 600 },
                                            }}
                                        >
                                            {Object.keys(LIVE_VARIANT_VERSIONS).map((version, index, arr) => {
                                                const currentVersion = LIVE_VARIANT_VERSIONS[version];
                                                return (
                                                    !currentVersion?.hideInProfile && (
                                                        <Tab
                                                            key={version}
                                                            label={capitalize(currentVersion.url)}
                                                            {...a11yProps(
                                                                index,
                                                                arr.length,
                                                                currentVersion?.disableInProfile
                                                            )}
                                                        />
                                                    )
                                                );
                                            })}
                                        </Tabs>
                                    </Box>

                                    {/* Error State */}
                                    {settingsError && (
                                        <div className="d-flex flex-column align-items-center py-4">
                                            <p className="text-danger mb-2">
                                                Failed to load notification settings
                                            </p>
                                            <button
                                                className="app-text-link"
                                                onClick={() => refetchSettings()}
                                            >
                                                Try Again
                                            </button>
                                        </div>
                                    )}

                                    {/* Content State */}
                                    {!settingsError && (isLoadingSettings || isFetchingSettings) ? (
                                        <div className="d-flex justify-content-center align-items-center py-4">
                                            <ClipLoader
                                                size={24}
                                                aria-label="Loading"
                                                data-testid="loader"
                                                color="#3B166A"
                                            />
                                        </div>
                                    ) : !settingsError &&
                                      Array.isArray(notificationSettings) &&
                                      notificationSettings.length > 0 ? (
                                        <>
                                            <CustomTabPanel value={tabValue} index={0}>
                                                <NotificationSetting
                                                    notificationSetting={notificationSettings}
                                                    notifyDailyProperty={
                                                        profileValues?.data?.notifyDailyProperty
                                                    }
                                                    setProfileValues={setProfileValues}
                                                    gameVersion="puzzle"
                                                />
                                            </CustomTabPanel>
                                            <CustomTabPanel value={tabValue} index={1}>
                                                <div className="section-group tw-py-2 tw-my-3 tw-border-b">
                                                    <div className="d-flex justify-content-between flex-wrap gap-2">
                                                        <p className="fw-bold mb-2 section-label">
                                                            Show my score on the leaderboard
                                                        </p>
                                                        <Switch
                                                            checked={
                                                                profileValues?.data?.showScoreLeaderboard
                                                            }
                                                            onChange={(e) => {
                                                                setProfileValues((prev) => ({
                                                                    ...prev,
                                                                    submit: true,
                                                                    data: {
                                                                        ...prev.data,
                                                                        showScoreLeaderboard:
                                                                            !prev.data.showScoreLeaderboard,
                                                                    },
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <NotificationSetting
                                                    notificationSetting={notificationSettings}
                                                    notifyDailyProperty={
                                                        profileValues?.data?.notifyDailyProperty
                                                    }
                                                    setProfileValues={setProfileValues}
                                                />
                                            </CustomTabPanel>
                                        </>
                                    ) : (
                                        <div className="d-flex justify-content-center align-items-center py-4">
                                            <p className="text-muted">No notification settings available</p>
                                            <button
                                                className="app-text-link ms-2"
                                                onClick={() => refetchSettings()}
                                            >
                                                Refresh
                                            </button>
                                        </div>
                                    )}
                                </Box>
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    );
};

export default ProfileDrawer;
