import React from "react";

// Third-party libraries
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";
import "swiper/css";

// Context providers
import { useLiveVariant } from "../../../context/LiveVariantContext";
import { useUser } from "../../../context/UserContext";

// Custom Hooks and Services
import useCustomLoginWithRedirect from "../../../hooks/useCustomLoginWithRedirect";
import useGAEvent from "../../../hooks/useGAEvent";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { getUserPuzzleStats, getUserPuzzleGuessDistribution } from "../../../services/UserService";

// Components - Common
import AppButton from "../../../components/common/buttons/app-button";
import CustomButton from "../../../components/common/buttons/custom-button/custom-button";

// Components - Leaderboard & Stats
import GuessDistributionChart from "./GuessDistributionChart";
import ArchiveSelectModal from "../../../pages/LiveVariant/components/Puzzle/ArchiveSelectModal";

import { triggerShare } from "../../../helpers/triggerShare";

const YourStats = ({ version }) => {
    const { userState } = useUser();
    const { sendEvent } = useGAEvent();
    const { isAuthenticated, isLoading } = useAuth0();
    const [visitorStatus] = useLocalStorage("visitor-status");
    const [puzzleShareObject] = useLocalStorage("puzzle_share_object", {});
    const { liveVariantState, setLiveVariantState } = useLiveVariant();

    const { customLoginWithRedirect } = useCustomLoginWithRedirect();

    const handleExploreArchive = () => {
        setLiveVariantState({ ...liveVariantState, show_archive_select_modal: true });
    };

    const userStats = useQuery({
        queryKey: ["user-puzzle-stats", userState?.id, isAuthenticated],
        queryFn: () => getUserPuzzleStats(userState?.id),
        enabled: isAuthenticated && !!userState?.id,
    });

    const guessDistributionQuery = useQuery({
        queryKey: ["user-puzzle-guess-distribution", userState?.id, isAuthenticated],
        queryFn: () => getUserPuzzleGuessDistribution(userState?.id),
        enabled: isAuthenticated && !!userState?.id,
    });

    const isQueryLoading = guessDistributionQuery?.isFetching || guessDistributionQuery?.isLoading;

    // Default Guess Distribution Data (Mocked)
    let guessDistribution = [
        { guess: "1", count: 6 },
        { guess: "2", count: 9 },
        { guess: "3", count: /* 41 */ 24 },
        { guess: "4", count: /* 57 */ 26 },
        { guess: "5", count: 9 },
        { guess: "6", count: 6 },
    ];

    const guessDistributionData = guessDistributionQuery?.data?.data;
    const todaysScore = guessDistributionData?.correctPuzzleGuessPosition ?? 0; // Number of guesses for puzzle
    let userGuessDistribution = guessDistributionData?.guessDistribution;

    const handleShare = async () => {
        sendEvent("share_score_leaderboard", {
            isAuthenticated: false,
            userRank: 0,
            finalScore: todaysScore,
            leaderboardType: "puzzle",
        });
        const { shareTextTitle, shareTextBody, shareUrl } = puzzleShareObject;

        await triggerShare({
            web: {
                text: `${shareTextTitle}${shareTextBody}\n\n${shareUrl}`,
            },
            mobile: {
                title: shareTextTitle,
                text: shareTextBody,
                url: shareUrl,
            },
            skipToast: true,
        });

        toast.success("Copied score to clipboard!", {
            duration: 1000, // 1 second
        });
    };

    const handleButtonClick = async () => {
        if (isAuthenticated) {
            if (todaysScore) {
                handleShare();
            } else {
                // Play Today
                window.location.href = "/puzzle";
            }
        } else {
            // Register / Sign-in
            sendEvent("leaderboard_register_tap", {
                isAuthenticated: false,
                userRank: 0,
                finalScore: todaysScore,
                leaderboardType: "puzzle",
            });

            customLoginWithRedirect({
                authorizationParams: {
                    redirect_uri: window.location.origin + "/puzzle",
                    screen_hint: "signup",
                },
                appState: {
                    version: "puzzle",
                },
            });
        }
    };

    if (userGuessDistribution) {
        guessDistribution = Object.values(userGuessDistribution).map((guess, index) => ({
            guess: index + 1,
            count: guess,
        }));
    }

    return (
        <>
            <p className="tw-text-[#6C26C4] tw-font-noto tw-font-bold tw-px-4 tw-mb-1">STATS</p>
            <div className="tw-flex tw-gap-5 tw-justify-between tw-px-1 tw-w-full">
                {[
                    { value: isAuthenticated ? userStats?.data?.data?.played ?? 0 : "?", label: "Played" },
                    {
                        value: isAuthenticated ? `${userStats?.data?.data?.winPercentage ?? 0}%` : "?",
                        label: "Win%",
                    },
                    {
                        value: isAuthenticated ? userStats?.data?.data?.currentStreak ?? 0 : "?",
                        label: "Current Streak",
                    },
                    {
                        value: isAuthenticated ? userStats?.data?.data?.longestStreak ?? 0 : "?",
                        label: "Max Streak",
                    },
                ].map((item, index) => (
                    <div key={index} className="tw-flex tw-flex-col tw-items-center tw-flex-1">
                        <span className="tw-font-noto tw-text-[20px] tw-text-[#100F13]">{item.value}</span>
                        <span className="tw-font-noto tw-text-[14px] tw-text-[#323137] tw-opacity-60 tw-text-center">
                            {item.label}
                        </span>
                    </div>
                ))}
            </div>
            <p className="tw-text-[#6C26C4] tw-font-noto tw-font-bold tw-px-4 tw-mt-4 tw-mb-2">
                GUESS DISTRIBUTION
            </p>

            {isAuthenticated && isQueryLoading ? (
                <p className="tw-text-center">Loading user guess distribution...</p>
            ) : (
                <GuessDistributionChart guessDistribution={guessDistribution} todaysScore={todaysScore} />
            )}

            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-6">
                <div className="d-flex flex-column cta-buttons px-2 py-2">
                    <AppButton className="register-button" theme="gold" onClick={handleButtonClick}>
                        {!isLoading && isAuthenticated
                            ? todaysScore
                                ? "Share Score"
                                : "Play Today"
                            : "Register to track your stats"}
                    </AppButton>
                </div>

                <hr className="tw-mx-4" />

                <div className="tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center">
                    <CustomButton
                        className="tw-text-base tw-bg-[#FAB85F] tw-text-[#27273B] tw-w-[200px] tw-rounded-[10px]"
                        text="Explore the archive"
                        handleClick={handleExploreArchive}
                    />
                    <CustomButton
                        className="tw-text-base tw-bg-[#BB84FF] tw-text-[#27273B] tw-w-[200px] tw-rounded-[10px]"
                        text="More games"
                        handleClick={() => (window.location.href = "/")}
                    />
                </div>
            </div>

            <ArchiveSelectModal
                score={todaysScore}
                version={version}
                visitorStatus={visitorStatus}
                resetGameState={() => {}}
            />
        </>
    );
};

export default YourStats;
