import React, { useState } from "react";

// Third-party libraries
import clsx from "clsx";
import "swiper/css";

// Components - Leaderboard & Stats
import CustomTabPanel from "../../../components/common/CustomTabPanel";
import YourStats from "./YourStats";

const PuzzleLeaderboard = ({ version }) => {
    const [tabValue, setTabValue] = useState(0);

    return (
        <>
            <div className="tab-buttons mb-3">
                <button
                    className={clsx(tabValue === 0 && "active", "!tw-text-center !tw-font-noto")}
                    onClick={() => setTabValue(0)}
                >
                    Your Stats
                </button>
                <button
                    className={clsx(tabValue === 1 && "active", "!tw-text-center !tw-font-noto")}
                    onClick={() => setTabValue(1)}
                >
                    Community Stats
                </button>
            </div>

            <CustomTabPanel value={tabValue} index={0}>
                <YourStats version={version} />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <p className="tw-text-center">Community Stats (Coming soon...)</p>
            </CustomTabPanel>
        </>
    );
};

export default PuzzleLeaderboard;
