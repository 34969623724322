import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import clsx from "clsx";

import AnimatedNumber from "../../../../components/animations/AnimatedNumber";
import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";
import CustomSvg from "../../../../components/common/custom-svg/custom-svg";

import DownArrow from "../../../../assets/icons-v2/less_icon.png";
import UpArrow from "../../../../assets/icons-v2/more_icon.png";

const BUTTON_WIDTHS = {
    default: 50,
    max: 100,
};

/**
 * Buttons component allows the user to choose between "Lower" and "Higher"
 * with animated buttons that change width based on the selection.
 *
 * @param {Object} props
 * @param {string} props.clickedButton - The currently clicked button ("lower" or "higher")
 * @param {string} props.bgColor - Background color for the buttons.
 * @param {function} props.onClick - Callback function to handle button click.
 * @param {number} props.actualPrice - The actual price that the user is guessing for.
 */
const Buttons = ({ clickedButton, bgColor, onClick, actualPrice = 0, isCorrect = false }) => {
    const [lowerButtonWidth, setLowerButtonWidth] = useState(BUTTON_WIDTHS.default);
    const [higherButtonWidth, setHigherButtonWidth] = useState(BUTTON_WIDTHS.default);

    const isLower = clickedButton === "lower";
    const isHigher = clickedButton === "higher";

    // Animate button widths when clickedButton changes
    useEffect(() => {
        let animationFrame;
        const startTime = performance.now();
        const duration = 500; // Animation duration: 0.5 second

        /**
         * Animates the button widths based on the selected button.
         * @param {number} currentTime - The current timestamp for animation.
         */
        const animateWidths = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const fullWidth = BUTTON_WIDTHS.default + progress * (BUTTON_WIDTHS.max - BUTTON_WIDTHS.default);
            const emptyWidth = BUTTON_WIDTHS.default - progress * BUTTON_WIDTHS.default;

            // Adjust widths based on the clicked button
            if (isLower) {
                setLowerButtonWidth(fullWidth);
                setHigherButtonWidth(emptyWidth);
            } else if (isHigher) {
                setHigherButtonWidth(fullWidth);
                setLowerButtonWidth(emptyWidth);
            } else {
                setLowerButtonWidth(BUTTON_WIDTHS.default);
                setHigherButtonWidth(BUTTON_WIDTHS.default);
            }

            // Continue the animation until it's done
            if (progress < 1) {
                animationFrame = requestAnimationFrame(animateWidths);
            }
        };

        // Start the animation
        animationFrame = requestAnimationFrame(animateWidths);

        // Cleanup on component unmount or when clickedButton changes
        return () => cancelAnimationFrame(animationFrame);
    }, [clickedButton]); // eslint-disable-line react-hooks/exhaustive-deps

    const lowerButtonAnimationComplete = lowerButtonWidth === BUTTON_WIDTHS.max;
    const higherButtonAnimationComplete = higherButtonWidth === BUTTON_WIDTHS.max;

    return (
        <>
            {lowerButtonWidth > 0 && (
                <div style={{ width: `${lowerButtonWidth}%` }}>
                    <CustomButton
                        className={clsx(
                            "tw-flex tw-w-full tw-h-[50px] tw-items-center tw-justify-center",
                            "tw-rounded-tl-[500px] tw-rounded-bl-[500px]",
                            "tw-text-[24px] tw-gap-2",
                            "tw-font-bold",
                            { "tw-rounded-tr-[500px] tw-rounded-br-[500px]": lowerButtonAnimationComplete },
                            bgColor && "tw-text-black",
                            lowerButtonAnimationComplete && bgColor
                                ? !isCorrect
                                    ? "!tw-bg-[#EA4949]"
                                    : "!tw-bg-[#28C76F]"
                                : "!tw-bg-[#6C26C4]"
                        )}
                        handleClick={() => onClick("lower")}
                        text={
                            isLower ? (
                                <AnimatedNumber
                                    value={!lowerButtonAnimationComplete ? 0 : actualPrice}
                                    className="tw-font-noto tw-text-[24px] tw-font-bold"
                                    shake={!isCorrect}
                                    start={lowerButtonAnimationComplete}
                                />
                            ) : (
                                "Less"
                            )
                        }
                        leftIcon={
                            !clickedButton && (
                                <CustomSvg
                                    className="tw-contrast-125 tw-brightness-110 tw-saturate-150 tw-object-cover"
                                    src={DownArrow}
                                    size={{ height: 15 }}
                                />
                            )
                        }
                        isDisabled={!isEmpty(clickedButton)}
                    />
                </div>
            )}

            {higherButtonWidth > 0 && (
                <div style={{ width: `${higherButtonWidth}%` }}>
                    <CustomButton
                        className={clsx(
                            "tw-flex tw-w-full tw-h-[50px] tw-items-center tw-justify-center tw-text-black",
                            "tw-text-[24px] tw-gap-2",
                            "tw-rounded-tr-[500px] tw-rounded-br-[500px]",
                            "tw-font-bold",
                            { "tw-rounded-tl-[500px] tw-rounded-bl-[500px]": higherButtonAnimationComplete },
                            bgColor && "tw-text-black",
                            higherButtonAnimationComplete && bgColor
                                ? !isCorrect
                                    ? "!tw-bg-[#EA4949]"
                                    : "!tw-bg-[#28C76F]"
                                : "!tw-bg-[#FAB85F]"
                        )}
                        handleClick={() => onClick("higher")}
                        text={
                            isHigher ? (
                                <AnimatedNumber
                                    value={!higherButtonAnimationComplete ? 0 : actualPrice}
                                    className="tw-font-noto tw-text-[20px] tw-font-bold !tw-text-black"
                                    shake={!isCorrect}
                                    start={higherButtonAnimationComplete}
                                />
                            ) : (
                                "More"
                            )
                        }
                        leftIcon={
                            !clickedButton && (
                                <CustomSvg
                                    className="tw-contrast-125 tw-brightness-110 tw-saturate-150 tw-object-cover"
                                    src={UpArrow}
                                    size={{ height: 15 }}
                                />
                            )
                        }
                        isDisabled={!isEmpty(clickedButton)}
                    />
                </div>
            )}
        </>
    );
};

Buttons.propTypes = {
    clickedButton: PropTypes.string.isRequired,
    bgColor: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    actualPrice: PropTypes.number.isRequired,
    isCorrect: PropTypes.bool,
};

export default Buttons;
