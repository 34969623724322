import axios from "axios";
import { filterEmptyProps } from "../helpers/helpers";
import moment from "moment-timezone";
import axiosInstance from "../helpers/axiosInstance";

const apiEndpoint = "/api/users";

/**
 * Function to send user data to the server to find or create a user record.
 *
 * @param {Object} user - The user object to be sent.
 * @param {Object} guestData - The guessData object to be sent.
 *
 * @returns {Promise} A promise that resolves with the server response.
 */
export async function findOrCreateUser(user, guestData = null) {
    return axios.post(`${apiEndpoint}/find_or_create`, { user_data: user, guest_data: guestData });
}

/**
 * Asynchronously synchronizes user data with the server by sending a PATCH request.
 *
 * @param {Object} user - The user data to be synchronized with the server.
 *
 * @returns {Promise<Object>} A promise that resolves with the server's response data.
 */
export async function syncUser(userData) {
    const { id } = userData;

    return axios.put(`${apiEndpoint}/${id}/sync`, userData);
}

/**
 * Asynchronously fetches daily properties data from the server based on the specified user ID.
 *
 * @param {number} userId - The ID of the user for which to fetch daily properties.
 * @param {boolean} isAuthenticated - User session.
 * @param {boolean} [generateIfEmpty=false] - The flag for generation of new user properties if none are found
 *
 * @returns {Promise} A Promise that resolves with the daily properties data.
 */
export const getDailyProperties = async (userId, isAuthenticated, generateIfEmpty = false) => {
    // If not authenticated return guest properties
    if (!isAuthenticated) {
        return await axiosInstance.get(`/api/properties/daily/guest-properties`);
    }

    return await axiosInstance.get(`${apiEndpoint}/${userId}/properties/daily?generate=${generateIfEmpty}`);
};

/**
 * Asynchronously fetches a property data from the server.
 *
 * @returns {Promise} A Promise that resolves a single random property data.
 */
export const getRandomProperty = async () => {
    return await axiosInstance.get(`/api/properties/daily/random`);
};

/**
 * Asynchronously retrieves user data for the specified user ID from the server.
 *
 * @param {string|number} userId - The ID of the user to retrieve data for.
 *
 * @returns {Promise<Object>} A promise that resolves with the server's response data.
 */
export async function findUser(userId) {
    return (await axios.get(`${apiEndpoint}/${userId}/find`)).data;
}

/**
 * Retrieves the notification settings for a user based on the provided user ID.
 *
 * @param {string} userId - The unique identifier of the user.
 * @returns {Promise<object>} A Promise that resolves to the notification settings for the user.
 * @throws {Error} Throws an error if the request to the API fails.
 */
export async function findUserNotificationSetting(userId) {
    return (await axios.get(`${apiEndpoint}/${userId}/findUserNotificationSetting`)).data;
}

/**
 * Retrieves the awards for a user based on the provided user ID.
 *
 * @param {string} userId - The unique identifier of the user.
 *
 * @returns {Promise<object>} A Promise that resolves to the awards for the user.
 * @throws {Error} Throws an error if the request to the API fails.
 */
export async function getAwards(userId) {
    return (await axios.get(`${apiEndpoint}/${userId}/awards`)).data;
}

/**
 * Fetches the user's guess data for a specific property ID.
 *
 * @param {number} userId - The unique identifier for the user.
 * @param {string} propertyId - The ID of the property
 * @param {string} playDate - The date it was created.
 * @param {string} userPropertyId - The ID of the user property record.
 *
 * @returns {Promise<object>} A promise representing the user's guess data.
 */
export async function getUserGuess(
    userId,
    propertyId,
    playDate,
    userPropertyId,
    playablePropertyId,
    version
) {
    return (
        await axios.get(`${apiEndpoint}/${userId}/guess`, {
            params: {
                property_id: propertyId,
                game_version: version,
                created_at: playDate,
                user_property_id: userPropertyId,
                playable_property_id: playablePropertyId,
            },
        })
    ).data;
}

/**
 * Get the daily score for a specific user and area.
 *
 * @param {string} userId - The ID of the user for whom to retrieve the daily score.
 * @param {number} areaId - The ID of the area for which to retrieve the daily score.
 * @param {boolean} listScores - Flag to include list of scores.
 *
 * @returns {Promise<Object>} A promise that resolves to the user's daily score data.
 *
 * @throws {Error} Throws an error if there are issues with fetching the user's daily score.
 */
export async function getUserScore(userId, areaId, listScores = false) {
    return (
        await axios.get(`${apiEndpoint}/${userId}/scores/daily`, {
            params: {
                date: new Date().toLocaleDateString("en-US", { timeZone: "America/Chicago" }),
                area_id: areaId,
                list_scores: listScores,
            },
        })
    ).data;
}

/**
 * Retrieves the weekly score data for a specific user and area.
 *
 * @param {string} userId - The ID of the user for whom to retrieve the weekly score data.
 * @param {string} areaId - The ID of the area for which to retrieve the weekly score data.
 *
 * @returns {Promise<Object>} A promise that resolves with the weekly score data object.
 */
export async function getWeeklyScore(
    userId,
    areaId,
    date = new Date().toLocaleDateString(),
    areaScope = "local"
) {
    const formattedDate = moment(date).tz("America/Chicago").format("MM/DD/YYYY");

    return (
        await axios.get(`${apiEndpoint}/${userId}/scores/weekly`, {
            params: {
                date: formattedDate,
                area_id: areaId,
                areaScope,
            },
        })
    ).data;
}

/**
 * Retrieves the weekly rank data for a specific user and area.
 *
 * @param {string} userId - The ID of the user for whom to retrieve the weekly rank data.
 * @param {string} areaId - The ID of the area for which to retrieve the weekly rank data.
 *
 * @returns {Promise<Object>} A promise that resolves with the weekly rank data object.
 */
export async function getWeeklyRank(userId, areaId, areaScope = "local") {
    const date = moment().tz("America/Chicago").format("MM/DD/YYYY");

    return (
        await axios.get(`${apiEndpoint}/${userId}/rank/weekly`, {
            params: {
                date,
                area_id: areaId,
                areaScope,
            },
        })
    ).data;
}

/**
 * Retrieves the daily rank data for a specific user and area.
 *
 * @param {string} userId - The ID of the user for whom to retrieve the daily rank data.
 * @param {string} areaId - The ID of the area for which to retrieve the daily rank data.
 *
 * @returns {Promise<Object>} A promise that resolves with the daily rank data object.
 */
export async function getDailyRank(userId, areaId, areaScope) {
    return (
        await axios.get(`${apiEndpoint}/${userId}/rank/daily`, {
            params: {
                date: new Date().toLocaleDateString(),
                area_id: areaId,
                areaScope,
            },
        })
    ).data;
}

/**
 * Submits a guess for a property.
 *
 * @param {string} userId - The ID of the user submitting the guess.
 * @param {string} propertyId - The ID of the property for which the guess is being submitted.
 * @param {number} guess - The guess score.
 * @param {string} challengeId - The ID of the challenge for which the guess is being submitted.
 * @param {string} userPropertyId - The ID of the user property for which the guess is being submitted.
 *
 * @returns {Promise<any>} A promise that resolves to the response from the server.
 */
export async function submitGuess(
    userId,
    propertyId,
    guess,
    challengeId,
    userPropertyId = null,
    shortLink,
    version = "classic",
    is_archive_play = false
) {
    return await axios.post(`${apiEndpoint}/submit`, {
        property_id: propertyId,
        user_id: userId,
        guess_score: guess,
        challenge_id: challengeId,
        short_link: shortLink,
        game_version: version,
        user_property_id: userPropertyId,
        is_archive_play,
    });
}

/**
 * Get rankings
 *
 * @param {string} user_id - The ID of the user for whom to retrieve the ranking data.
 * @param {string} area_id - The ID of the area for whom to retrieve the ranking data.
 * @param {object} period - The range of date of the ranking data.
 * @param {string} area_id - The ID of the area for whom to retrieve the ranking data.
 *
 */

export async function getRankings(user_id, area_id, period, date, areaScope = "local") {
    const timeZoneOffset = new Date().getTimezoneOffset();
    const query = new URLSearchParams({ area_id, period, date, timeZoneOffset, areaScope }).toString();
    return await axios.get(`${apiEndpoint}/${user_id}/scores/top?${query}`);
}

/**
 * Get all scores per perdiod
 *
 * @param {string} user_id - The ID of the user for whom to retrieve the scores data.
 * @param {string} area_id - The ID of the area for whom to retrieve the scores data.
 * @param {object} date_to - The range of date of the scores data.
 * @param {string} date_from - The range of date of the scores data.
 *
 */
export async function getScores(user_id, area_id, date_to, date_from, areaScope) {
    const payload = {
        user_id,
        area_id,
        date_to,
        date_from,
        areaScope,
    };

    const filteredPayload = filterEmptyProps(payload);

    const query = new URLSearchParams(filteredPayload).toString();
    return await axios.get(`${apiEndpoint}/${user_id}/scores?${query}`);
}

/**
 * Get streaks
 *
 * @param {string} user_id - The ID of the user for whom to retrieve the streaks data.
 *
 */
export async function getStreaks(user_id) {
    return await axios.get(`${apiEndpoint}/${user_id}/streaks`);
}

/**
 * Get title text for a user.
 *
 * @param {string} user_id - The ID of the user for which to retrieve title text.
 *
 * @returns {Promise<string>} - A promise that resolves to the title text.
 */
export async function getTitleText(user_id) {
    const date = moment().tz("America/Chicago").format("MM/DD/YYYY");

    return (
        await axios.get(`${apiEndpoint}/${user_id}/title`, {
            params: { date },
        })
    ).data;
}

/**
 * Get share text for a user.
 *
 * @param {string} user_id - The ID of the user for which to retrieve share text.
 *
 * @returns {Promise<string>} - A promise that resolves to the share text.
 */
export async function getShareText(user_id) {
    return (await axios.get(`${apiEndpoint}/${user_id}/share`)).data;
}

/**
 * Get user guesses per period
 *
 * @param {string} user_id - The ID of the user for whom to retrieve the guesses per period.
 * @param {string} date - The period of user guesses to get.
 *
 */
export async function getUserGuessesPerPeriod({ user_id, date, is_challenge }) {
    let payload = {
        date,
        is_challenge,
    };

    // If date is empty then delete from payload
    if (!date) {
        delete payload.date;
    }

    // If is_challenge is empty then delete from payload
    if (!is_challenge) {
        delete payload.is_challenge;
    }

    const query = new URLSearchParams(payload).toString();
    return await axios.get(`${apiEndpoint}/${user_id}/guesses?${query}`);
}

export async function getUserChallengeResults(user_id) {
    return await axios.get(`${apiEndpoint}/${user_id}/challenge_results`);
}

export async function setUserViewedChallengeScores(viewedChallengeScoreIds) {
    return await axios.post(`${apiEndpoint}/viewed_challenge_scores`, {
        viewed_challenge_score_ids: viewedChallengeScoreIds,
    });
}

export async function getUserChallengesHistory(user_id) {
    return await axios.get(`${apiEndpoint}/${user_id}/user_challenges_history`);
}

export async function getUserPuzzleStats(user_id) {
    return await axios.get(`${apiEndpoint}/${user_id}/puzzle/stats`);
}

export async function getUserPuzzleGuessDistribution(user_id) {
    return await axios.get(`${apiEndpoint}/${user_id}/puzzle/guess-distribution`);
}
