// =================== CONSTANT VALUES  ========================

export const NOTIFICATION_FREQUENCIES = [{ name: "daily" }, { name: "weekly" }, { name: "never" }];

// constants to map message type and label
export const SUPPORTED_MESSAGE_TYPES = {
    classic: [
        {
            message_type: "new_listings_available",
            action_type: "dropdown",
            label: "When a new property set is available:",
            support_push: true,
            support_email: true,
            push_frequency: "never",
            email_frequency: "daily",
            has_frequency_label: false,
            supported_frequencies: [{ name: "daily" }, { name: "weekly" }, { name: "never" }],
        },
        {
            message_type: "new_user_tips",
            action_type: "dropdown",
            label: "New user tips (First week):",
            support_push: false,
            support_email: true,
            push_frequency: "never",
            email_frequency: "daily",
            has_frequency_label: false,
            supported_frequencies: [{ name: "daily" }, { name: "never" }],
        },
        {
            message_type: "user_referral",
            action_type: "switch",
            label: "When someone I told about PriceMe signs up:",
            support_push: true,
            support_email: true,
            push_frequency: "never",
            email_frequency: "daily",
            has_frequency_label: true,
            supported_frequencies: [
                { name: "On", value: "daily" },
                { name: "Off", value: "never" },
            ],
        },
        {
            message_type: "user_awards",
            action_type: "switch",
            label: "When I win a trophy:",
            support_push: false,
            support_email: true,
            push_frequency: "never",
            email_frequency: "daily",
            has_frequency_label: true,
            supported_frequencies: [
                { name: "On", value: "daily" },
                { name: "Off", value: "never" },
            ],
        },
        {
            message_type: "challenge_accept",
            action_type: "switch",
            label: "When someone accepts my challenge:",
            support_push: true,
            support_email: true,
            push_frequency: "never",
            email_frequency: "daily",
            has_frequency_label: true,
            supported_frequencies: [
                { name: "On", value: "daily" },
                { name: "Off", value: "never" },
            ],
        },
    ],
    puzzle: [
        {
            message_type: "new_daily_puzzle",
            action_type: "dropdown",
            label: "When the new daily property is available:",
            support_push: true,
            support_email: true,
            push_frequency: "never",
            email_frequency: "daily",
            has_frequency_label: false,
            supported_frequencies: [{ name: "daily" }, { name: "weekly" }, { name: "never" }],
        },
    ],
};

export const getDefaultSetting = (notify_daily = true, version = "classic") => {
    return [
        {
            message_type: "new_listings_available",
            action_type: "dropdown",
            push_frequency: "never",
            email_frequency: notify_daily && version === "classic" ? "daily" : "never",
        },
        {
            message_type: "new_user_tips",
            action_type: "dropdown",
            push_frequency: "never",
            email_frequency: version === "classic" ? "daily" : "never",
        },
        {
            message_type: "user_referral",
            action_type: "switch",
            push_frequency: "never",
            email_frequency: version === "classic" ? "daily" : "never",
        },
        {
            message_type: "user_awards",
            action_type: "switch",
            push_frequency: "never",
            email_frequency: version === "classic" ? "daily" : "never",
        },
        {
            message_type: "challenge_accept",
            action_type: "switch",
            push_frequency: "never",
            email_frequency: version === "classic" ? "daily" : "never",
        },
        {
            message_type: "new_daily_puzzle",
            action_type: "dropdown",
            push_frequency: version === "puzzle" ? "daily" : "never",
            email_frequency: version === "puzzle" ? "daily" : "never",
        },
        // add more default notification settings
    ];
};

// This is the sorting order for the notifications based on the SUPPORTED_MESSAGE_TYPES constant
export const NOTIF_TYPE_SORT_ORDER = (gameVersion) => {
    return SUPPORTED_MESSAGE_TYPES[gameVersion].reduce((acc, curr, index) => {
        return {
            ...acc,
            [curr["message_type"]]: index + 1,
        };
    }, {});
};

// ============================================================
