import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { get, isEmpty, isNil, uniqBy, uniq, isEqual } from "lodash";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import Sticky from "react-sticky-el";
import MoonLoader from "react-spinners/MoonLoader";
import moment from "moment-timezone";
import clsx from "clsx";

import config from "../../../../config";
import PropertyInfo from "../../../../components/common/PropertyInfo";
import CustomSvg from "../../../../components/common/custom-svg/custom-svg";
import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";
import FlipDailyScoreModal from "./FlipDailyScoreModal";

import { LIVE_VARIANT_VERSIONS } from "../../../../constants/live-variant-versions";

import { getLiveVariantDailyProperty, getNearestProperty } from "../../../../services/PropertyService";
import { formatPrice, wait } from "../../../../helpers/helpers";
import { triggerShare } from "../../../../helpers/triggerShare";
import useGAEvent from "../../../../hooks/useGAEvent";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import useModalState from "../../../../hooks/useModalState";

import HeartOutlined from "../../../../assets/icons-v2/heart_outlined.png";
import HeartSolid from "../../../../assets/icons-v2/heart_solid.png";
import Buttons from "./Buttons";
import NumberRoller from "../../../../components/animations/NumberRoller";
import AnimateShakeAndMeltdown from "../../../../components/animations/AnimateShakeAndMeltdown";
import { useAnimationContext } from "../../../../context/AnimationContext";
import { useLiveVariant } from "../../../../context/LiveVariantContext";

const THRESHOLD = 5;
const INITIAL_LIMIT = 10;
const POINTS_MULTIPLIER = 100;

const slideVariants = {
    hidden: { y: 0, opacity: 1 }, // Start position
    slideUp: ({ heightOffset }) => ({ y: `-${heightOffset}px`, opacity: 1, transition: { duration: 0.5 } }), // Slide up animation // TODO: Get the height of the propertyinfo component + its price below
    slideIn: { y: 0, opacity: 1, transition: { duration: 0 } }, // End
};

const containerVariants = {
    animate: {
        transition: {
            staggerChildren: 0.5, // Adjust delay between child animations
        },
    },
};

/**
 * Filters an array to ensure no two properties have the same price.
 * Handles fallback by checking nearest properties to find one with a unique price.
 *
 * @param {Array} properties - The current properties array.
 * @param {Array} nearestProperties - The fallback nearest properties array.
 * @returns {Array} Updated properties array ensuring unique prices for propertyA and propertyB.
 */
const getUniquePriceProperties = (properties, nearestProperties) => {
    const [propertyA, propertyB, propertyC] = properties;

    // Check if propertyA and propertyB have the same price
    if (propertyA?.price === propertyB?.price) {
        // Check if propertyC can replace propertyB
        if (propertyA?.price !== propertyC?.price) {
            return uniqBy([propertyA, propertyC, propertyB].filter(Boolean), "mls_id");
        }

        // Find the first property in nearestProperties with a different price
        const newPropertyB = nearestProperties.find((property) => property?.price !== propertyA?.price);

        if (newPropertyB) {
            return uniqBy([propertyA, newPropertyB, propertyB].filter(Boolean), "mls_id");
        }
    }

    return uniqBy(properties, "mls_id"); // Return original properties
};

/**
 * Live variant game version component that allows users to guess whether the price of Property A is higher or lower than of property B.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.version - The version of the live variant game.
 * @returns {JSX.Element} The live variant game interface.
 */
export default function Flip({ version }) {
    const { startAnimation } = useAnimationContext();
    const bodyRef = useRef(null);
    const propertyARef = useRef(null);
    const [heightOffset, setHeightOffset] = useState(0);
    const [points, setPoints] = useState(0);
    const [yesterdaysProperty, setYesterdaysProperty] = useState(null);
    const [todaysProperty, setTodaysProperty] = useState(null);
    const [nearestProperties, setNearestProperties] = useState([]);
    const [properties, setProperties] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isSliding, setIsSliding] = useState(false);
    const [bgColor, setBgColor] = useState(null);
    const [clickedButton, setClickedButton] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const { sendEvent } = useGAEvent();
    const { setModalId } = useModalState();
    const [shownHelpModals, setShownHelpModal] = useLocalStorage("shown-help-modals", []);
    const [v3FlipState, setV3FlipState] = useLocalStorage("v3-flip-state");
    const [guessedProperties, setGuessedProperties] = useLocalStorage("guessed_properties", []);
    const [visitorStatus] = useLocalStorage("visitor-status");
    const { liveVariantState } = useLiveVariant();
    const currentVersion = LIVE_VARIANT_VERSIONS[version];
    const maxTries = currentVersion?.max_tries ?? null;
    const [tries, setTries] = useState(0);
    const { date, propertyDetails } = useParams();
    let variant_link = `${date}/${propertyDetails}`;

    if (window.location?.hash) {
        variant_link += window.location.hash; // Append the hash fragment
    }
    const isNewGame = !v3FlipState?.status || v3FlipState?.status === "new_game";
    const isPropertyGuessed = v3FlipState?.status === "game_end";
    const yesterdayDate = isNewGame
        ? moment().tz("America/Chicago").subtract(1, "days").format("YYYY-MM-DD")
        : "";

    // Map guesses to emojis
    const guessMap = v3FlipState?.guessMap || [];
    const emojis = guessMap.map((guess) => {
        if (guess === "higher") return "⬆️";
        if (guess === "lower") return "⬇️";
        return "❌";
    });

    // Group emojis into rows of 5
    const groupedEmojis = emojis.reduce((rows, emoji, index) => {
        const rowIndex = Math.floor(index / 5);
        rows[rowIndex] = rows[rowIndex] || [];
        rows[rowIndex].push(emoji);
        return rows;
    }, []);

    const puzzleResult = (
        <div className="tw-flex tw-flex-col tw-items-center tw-gap-1 tw-font-bold tw-text-lg">
            {groupedEmojis.map((row, index) => (
                <div key={index} className="tw-flex tw-justify-center tw-gap-2">
                    {row.map((emoji, i) => (
                        <span key={i}>{emoji}</span>
                    ))}
                </div>
            ))}
        </div>
    );

    /**
     * Fetches the property for the given date with retry logic to find a valid property.
     * Retries recursively by decrementing the date if the property is not found or if it matches today's property.
     *
     * @param {string} date - The date to fetch the property for in YYYY-MM-DD format.
     * @param {number} [retryCount=0] - The current retry count, defaults to 0.
     * @param {number} [maxRetryCount=100] - The maximum number of retries allowed, defaults to 100.
     * @returns {Promise<void>} Resolves when a valid property is found or the retry limit is reached.
     */
    const fetchYesterdaysProperty = async (date, retryCount = 0, maxRetryCount = 100) => {
        if (retryCount >= maxRetryCount) {
            console.warn("Max retries reached. No valid property found.");
            return;
        }

        try {
            const data = await getLiveVariantDailyProperty({ play_date: date });
            const property = get(data, "data.properties", null);

            // Retry if the fetched property is the same as today's property
            if (todaysProperty && isEqual(todaysProperty, property)) {
                const newDate = moment(date).subtract(1, "days").format("YYYY-MM-DD");
                return fetchYesterdaysProperty(newDate, retryCount + 1, maxRetryCount);
            }

            // Set yesterdays property if found
            if (property?.price) {
                setYesterdaysProperty(property);
            } else {
                // Retry with the earlier date if no property is found
                const newDate = moment(date).subtract(1, "days").format("YYYY-MM-DD");
                return fetchYesterdaysProperty(newDate, retryCount + 1, maxRetryCount);
            }
        } catch (error) {
            // Retry on error with the earlier date
            const newDate = moment(date).subtract(1, "days").format("YYYY-MM-DD");
            return fetchYesterdaysProperty(newDate, retryCount + 1, maxRetryCount);
        }
    };

    // Yesterday's daily property
    useQuery({
        queryKey: ["live-variant-yesterdays-daily-property", yesterdayDate],
        queryFn: () => fetchYesterdaysProperty(yesterdayDate),
        enabled: isNewGame && !isPropertyGuessed,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.properties", null);
            if (property?.price) {
                setYesterdaysProperty(property);
            }
        },
    });

    // Today's daily property
    const propertyB = useQuery({
        queryKey: ["live-variant-daily-property", variant_link],
        queryFn: () => getLiveVariantDailyProperty({ variant_link }),
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.properties", null);
            if (property?.price) {
                setTodaysProperty(property);
            }
        },
    });

    const location = todaysProperty?.street_address.split(",")[0];

    // Nearest properties
    // Based on user's previous location setting, if available or
    // Based on location of property B, if user does not have a previous location setting
    useQuery({
        queryKey: ["live-variant-nearest-properties", location, guessedProperties, INITIAL_LIMIT],
        queryFn: () => getNearestProperty({ location, guessedProperties, limit: INITIAL_LIMIT }),
        enabled: !isEmpty(location) && nearestProperties.length < THRESHOLD && !isPropertyGuessed,
        cacheTime: 0,
        onSuccess: (data) => {
            const extraProperties = get(data, "data.properties", null);
            if (extraProperties?.length) {
                const updatedNearestProperties = uniqBy([...nearestProperties, ...extraProperties], "mls_id");
                setNearestProperties(updatedNearestProperties);

                if (properties.length < 3) {
                    setProperties(
                        getUniquePriceProperties(
                            [...properties, extraProperties[0]].filter(Boolean),
                            updatedNearestProperties
                        )
                    );
                }
            }
        },
        onError: (error) => {
            if (error?.response?.status === 500) {
                toast.error("Currently, There are no nearby properties. Please try again later.");
            }
        },
    });

    const isLoading = properties.length < 2;
    const propertyAPrice = properties[0]?.price;
    const propertyBPrice = properties[1]?.price;

    const animateToColor = (color, duration = 1500) => {
        return new Promise(async (resolve) => {
            setBgColor(null);
            await wait(duration);
            setBgColor(color);
            await wait(isCorrect ? 1000 : 1500);
            setBgColor(null);
            setClickedButton(null);
            resolve();
        });
    };

    const animateSlideUp = (duration = 1000) => {
        return new Promise(async (resolve) => {
            setIsSliding(true);
            await wait(duration);
            setIsSliding(false);
            resolve();
        });
    };

    /**
     * Updates the points value by applying an adjustment and triggers an animation.
     *
     * - Adjusts the current points by adding the given adjustment value.
     * - Updates the points state and starts an animation to display the change.
     *
     * @param {number} adjustment - The value to adjust the current points by. Can be positive or negative.
     * @returns {Promise<void>} Resolves when the animation completes.
     *
     * @example
     * // Increase points by 5
     * await updatePoints(5);
     *
     * // Decrease points by 3
     * await updatePoints(-3);
     */
    const updatePoints = async (adjustment) => {
        const newPoints = points + adjustment;

        setPoints(newPoints);
        await startAnimation("flip-number-roller", newPoints);
    };

    /**
     * Handles the user's guess for whether the price is higher or lower.
     *
     * @param {string} buttonType - The type of guess ("lower" or "higher").
     */
    const handleClick = (buttonType) => {
        setClickedButton(buttonType);
        setGuessedProperties(uniq([...guessedProperties, properties[1]?.mls_id]));
        sendEvent("enter_guess", {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus,
            userScore: points,
        });

        const isCorrect =
            (buttonType === "lower" && propertyBPrice < propertyAPrice) ||
            (buttonType === "higher" && propertyBPrice > propertyAPrice);

        let guessMap = v3FlipState?.guessMap ?? [];

        if (isCorrect) {
            guessMap = [...guessMap, buttonType];
        } else {
            guessMap = [...guessMap, "incorrect"];
            setTries((prevTries) => prevTries + 1); // Increment tries on incorrect guess
        }

        setIsCorrect(isCorrect);
        setHeightOffset(propertyARef.current.clientHeight);

        if (isCorrect) {
            const newSet = getUniquePriceProperties(
                [properties[1], nearestProperties[1], nearestProperties[2]].filter(
                    (property) => !isNil(property)
                ),
                nearestProperties
            );
            (async () => {
                await animateToColor("#7CC836", 2000);
                await updatePoints(POINTS_MULTIPLIER);
                await animateSlideUp();

                setProperties(newSet);
                await wait(1000);
                setNearestProperties(nearestProperties.slice(1));
                setV3FlipState({
                    ...v3FlipState,
                    dailyPropertyMlsId: todaysProperty.mls_id,
                    points: points + POINTS_MULTIPLIER,
                    properties: newSet,
                    status: "game_inprogress",
                    guessMap,
                    tries, // Track tries in local storage
                });
            })();
        } else {
            (async () => {
                await animateToColor("#FF0000");

                if (tries + 1 >= maxTries) {
                    // Check if max tries reached
                    setV3FlipState({
                        ...v3FlipState,
                        dailyPropertyMlsId: todaysProperty.mls_id,
                        points,
                        properties,
                        status: "game_end",
                        guessMap,
                        tries: tries + 1,
                    });
                    await wait(500);
                    setShowModal("dailyscore");
                } else {
                    // if user guess is incorrect then this block will run.
                    const newSet = getUniquePriceProperties(
                        [properties[1], nearestProperties[1], nearestProperties[2]].filter(
                            (property) => !isNil(property)
                        ),
                        nearestProperties
                    );

                    await animateSlideUp();
                    setProperties(newSet);
                    await wait(1000);
                    setNearestProperties(nearestProperties.slice(1));
                    setV3FlipState({
                        ...v3FlipState,
                        dailyPropertyMlsId: todaysProperty.mls_id,
                        points: points,
                        properties: newSet,
                        status: "game_inprogress",
                        guessMap,
                        tries: tries + 1,
                    });
                }
            })();
        }
    };

    const handleShare = async () => {
        const score = v3FlipState?.points || points;

        sendEvent("daily_score_share_score", {
            userScore: score,
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus,
        });

        const currentDate = moment(date, "YYMMDD").format("MMM D, YYYY");

        // Format the emoji rows into strings
        const emojiText = groupedEmojis.map((row) => row.join("")).join("\n");
        const shareTextTitle = `PriceMe Total Score: ${score ?? 0}\n`;
        const shareTextBody = `${emojiText}\n${currentDate}`;
        const shareUrl = `${config.BASE_URL}/${currentVersion.url}/${liveVariantState?.shortLink}?utm_medium=share`;

        await triggerShare({
            web: {
                text: `${shareTextTitle}${shareTextBody}\n\n${shareUrl}`,
            },
            mobile: {
                title: shareTextTitle,
                text: shareTextBody,
                url: shareUrl,
            },
        });
    };

    const renderMeltHearts = () => {
        return Array.from({ length: maxTries }, (_, index) => {
            const isLost = index < tries;

            return (
                <AnimateShakeAndMeltdown
                    key={index}
                    play={isLost}
                    className="tw-mr-1"
                    outlinedChild={<CustomSvg src={HeartOutlined} size={{ width: 22 }} />}
                >
                    <CustomSvg src={HeartSolid} size={{ width: 22 }} />
                </AnimateShakeAndMeltdown>
            );
        });
    };

    const handleTryAgain = () => {
        setV3FlipState({
            ...v3FlipState,
            points: 0,
            properties: [properties[1], properties[2]],
            status: "game_inprogress",
            hasTriedAgain: true,
            guessMap: [],
            tries: 0, // Reset tries
        });
        setShowModal(false);
        window.location.reload();
    };

    useEffect(() => {
        sendEvent("start_page_load", {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //onload this useffect will reset guessMap.
        window.onload = () => {
            setV3FlipState({
                ...v3FlipState,
                guessMap: [],
            });
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (v3FlipState?.guessMap.length > 0) {
            setV3FlipState({
                ...v3FlipState,
                guessMap: [],
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Function to clear localStorage at midnight CST
        const resetFlipState = () => {
            localStorage.removeItem("v3-flip-state");
        };

        // Get current time in CST
        const now = moment().tz("America/Chicago");

        // Calculate next midnight CST
        const nextMidnight = now.clone().add(1, "days").startOf("day");

        // Time until next midnight (in milliseconds)
        const timeUntilMidnight = nextMidnight.diff(now);

        // First Timeout to run at the next 12 AM CST
        const timeoutId = setTimeout(() => {
            resetFlipState();
            window.location.reload();
        }, timeUntilMidnight);

        return () => clearTimeout(timeoutId);
    }, [
        v3FlipState?.todayDate,
        moment().tz("America/Chicago").format("YYYY-MM-DD"),
        v3FlipState?.dailyPropertyMlsId,
        propertyB?.mls_id,
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            v3FlipState?.dailyPropertyMlsId &&
            propertyB?.data?.data?.properties.mls_id &&
            v3FlipState.dailyPropertyMlsId !== propertyB.data.data.properties.mls_id
        ) {
            // reset v3FlipState
            setV3FlipState({
                dailyPropertyMlsId: propertyB.data.data.properties.mls_id,
                points: 0,
                properties: [],
                guessMap: [],
                status: "new_game",
                todayDate: moment().tz("America/Chicago").format("YYYY-MM-DD"),
            });
        }
    }, [v3FlipState?.dailyPropertyMlsId, propertyB?.mls_id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isEmpty(properties) && yesterdaysProperty && todaysProperty) {
            const newGameProperties = [yesterdaysProperty, todaysProperty].filter(
                (property) => !isNil(property)
            );
            setProperties(getUniquePriceProperties(newGameProperties, nearestProperties));

            setV3FlipState({
                dailyPropertyMlsId: todaysProperty.mls_id,
                points: 0,
                properties: newGameProperties,
                guessMap: [],
                status: "new_game",
                todayDate: moment().tz("America/Chicago").format("YYYY-MM-DD"),
            });
        }
    }, [properties, yesterdaysProperty, todaysProperty]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(properties) && !isEmpty(nearestProperties) && properties.length < 3) {
            const restOfProperties = v3FlipState?.hasTriedAgain ? v3FlipState?.properties : properties;
            setProperties(
                getUniquePriceProperties(
                    [...restOfProperties, nearestProperties[0]].filter((property) => !isNil(property)),
                    nearestProperties
                )
            );
            setV3FlipState({
                ...v3FlipState,
                hasTriedAgain: false,
            });
            setNearestProperties(nearestProperties.slice(1));
        }
    }, [properties.length, nearestProperties]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPropertyGuessed && v3FlipState?.points && v3FlipState.points !== points) {
            setPoints(v3FlipState.points);
        }
    }, [v3FlipState?.points]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPropertyGuessed && v3FlipState?.properties && v3FlipState.properties !== properties) {
            setProperties(
                getUniquePriceProperties(
                    v3FlipState.properties.filter((property) => !isNil(property)),
                    nearestProperties
                )
            );
        }
    }, [v3FlipState?.properties]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isLoading && !shownHelpModals?.includes(version)) {
            setShownHelpModal([...shownHelpModals, version]);
            setModalId("LIVE_VARIANT_HELP_MODAL");
        }
    }, [isLoading, shownHelpModals]); // eslint-disable-line react-hooks/exhaustive-deps

    if (propertyB?.error) {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4 tw-p-8">
                {propertyB.error.response?.data?.message ||
                    "Failed to load daily property. Please try again later."}
            </div>
        );
    }

    return isLoading ? (
        <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4">
            <MoonLoader size={40} color="#63c19f" />
        </div>
    ) : (
        <motion.div
            className="property-container"
            ref={bodyRef}
            variants={containerVariants}
            initial="hidden"
            animate="animate"
        >
            {properties.map((property, index) => {
                if ((index > 1 && !isSliding) || !property) return null;

                return (
                    <>
                        <motion.div
                            initial="hidden"
                            animate={isSliding ? "slideUp" : "slideIn"}
                            variants={slideVariants}
                            custom={{
                                heightOffset,
                            }}
                            ref={index === 0 ? propertyARef : null}
                        >
                            <PropertyInfo property={property} showMoreDetails={false} index={index} />
                            {index === 0 || (index === 1 && isSliding) ? (
                                <>
                                    <div className="!tw-mb-[-25px] tw-mt-[-74px] md:tw-mt-[-84px] tw-flex tw-justify-center">
                                        <div className=" tw-flex tw-relative tw-justify-center tw-items-center tw-max-w-[600px] tw-w-full tw-border-b-[#2E2E2E] tw-border-b-[4.5svh] md:tw-border-b-[3svh] lg:tw-border-b-[6svh] tw-mb-[1.2px] lg:tw-mb-[1px]">
                                            <div
                                                className={clsx(
                                                    {
                                                        "tw-bg-[#6C26C4] 'tw-border-[1px]' tw-border-[#3B166A] tw-drop-shadow-[0_1px_1px_#3B166A]":
                                                            !isPropertyGuessed,
                                                    },
                                                    {
                                                        "tw-bg-[#9E9E9E] tw-drop-shadow-[0_1px_1px_#9E9E9E]":
                                                            isPropertyGuessed,
                                                    },
                                                    "tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-[45px] tw-px-[40px]  md:tw-px-[55px] tw-py-1 md:tw-py-1 tw-leading-[1.2] tw-relative tw-top-[65px] md:tw-top-[75px]  tw-z-[9]"
                                                )}
                                            >
                                                <p className="tw-font-light tw-text-[#D9D9D9] tw-text-center tw-text-[12px] tw-py-1 md:tw-pb-3.5">
                                                    is listed for
                                                </p>
                                                {/* This just serves a place holder having an opacity of 0 because we need the price to have higher zIndex */}
                                                <span className="tw-font-noto tw-text-[18px] md:tw-text-[23px] lg:tw-text-[10px]  tw-font-bold tw-opacity-0">
                                                    {formatPrice(property?.price)}
                                                </span>
                                            </div>

                                            {
                                                /* This is the actual price being shown with higher zIndex*/
                                                index === 0 && (
                                                    <span
                                                        className={clsx(
                                                            "tw-font-noto tw-text-[28px] md:tw-text-[22px] lg:tw-text-[18px] tw-font-bold tw-absolute tw-z-[10] tw-bottom-[-52px] md:tw-bottom-[-55px]",
                                                            {
                                                                "tw-text-white tw-text-stroke-[1px] tw-text-stroke-[#3B166A]":
                                                                    !isPropertyGuessed,
                                                            }
                                                        )}
                                                    >
                                                        {formatPrice(property?.price)}
                                                    </span>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="tw-absolute tw-w-full tw-flex tw-justify-center">
                                        <span
                                            className={clsx(
                                                {
                                                    "tw-text-white tw-bg-[#6C26C4] tw-text-stroke-[1px] tw-text-stroke-[#3B166A]":
                                                        !isPropertyGuessed,
                                                },
                                                {
                                                    "tw-text-[#100F13] tw-bg-[#9E9E9E] tw-drop-shadow-[0_1px_1px_#9E9E9E]":
                                                        isPropertyGuessed,
                                                },
                                                "tw-text-[16px] tw-rounded-full tw-pt-[10px] tw-px-[9px] tw-font-sans tw-relative tw-z-[9] tw-font-bold tw-h-[40px]   tw-w-[40px]  tw-top-[30px] md:tw-top-[40px] lg:tw-top-[30px]"
                                            )}
                                        >
                                            VS
                                        </span>
                                    </div>
                                    <br />
                                </>
                            ) : null}
                        </motion.div>
                    </>
                );
            })}

            {}
            <Sticky
                className="tw-sticky tw-bottom-0 tw-z-10 tw-w-full"
                mode="bottom"
                positionRecheckInterval={50}
            >
                <div className="!tw-bg-[#2E2E2E] guess-input-container tw-overflow-hidden !tw-px-4 !tw-py-4 lg:tw-py-0 guess-input-container-shadow tw-h-[21svh] md:tw-h-[18svh] lg:tw-h-[21.5svh] tw-flex tw-flex-col tw-justify-center tw-gap-1 md:tw-gap-3 lg:tw-gap-1">
                    <p className="tw-font-normal tw-text-[#D9D9D9] tw-text-center tw-text-[14px] tw-mb-1">
                        is listed for
                    </p>
                    {isPropertyGuessed ? (
                        <div className="tw-bg-[#9E9E9E] tw-w-full tw-h-[38px] tw-flex tw-justify-center tw-items-center tw-rounded-[500px] tw-mb-2">
                            <span className="tw-text-black tw-font-noto tw-text-[28px] tw-font-bold">
                                {formatPrice(v3FlipState?.properties[1]?.price)}
                            </span>
                        </div>
                    ) : (
                        <div className="tw-bg-[#FAB85F] tw-h-[50px] tw-w-full tw-flex tw-items-center tw-rounded-500px">
                            <Buttons
                                clickedButton={clickedButton}
                                bgColor={bgColor}
                                onClick={handleClick}
                                actualPrice={propertyBPrice}
                                isCorrect={isCorrect}
                            />
                        </div>
                    )}

                    <div className="tw-flex tw-items-center tw-justify-center tw-w-full">
                        <div className="tw-w-[25%] tw-justify-center">
                            {isPropertyGuessed && (
                                <CustomButton
                                    className="tw-w-full white-outlined tw-flex tw-items-center tw-justify-center tw-rounded-[500px]"
                                    handleClick={() => setShowModal("dailyscore")}
                                    text="Done"
                                />
                            )}
                        </div>
                        <div className="tw-w-[50%] tw-justify-center score-to-beat !tw-max-w-full tw-px-3 tw-flex tw-gap-1">
                            <span className="tw-text-gray-901 text-sm tw-font-noto tw-text-right">
                                Your Score:
                            </span>
                            <span
                                className={clsx(
                                    {
                                        "tw-w-[40%]": points >= 1000,
                                        "tw-w-[25%]": points < 1000 && points > 0,
                                        "tw-w-[15%]": points === 0,
                                    },
                                    "app-text-title tw-text-yellow-901 !tw-font-noto score !tw-text-[21px] tw-text-left"
                                )}
                            >
                                <NumberRoller animationKey="flip-number-roller" targetNumber={points} />
                            </span>
                        </div>
                        <div className="tw-w-[25%] tw-flex tw-justify-center tw-items-center tw-gap-1">
                            {isPropertyGuessed ? (
                                <CustomButton
                                    className="tw-w-full tw-flex tw-items-center tw-justify-center gold-solid tw-rounded-[500px]"
                                    handleClick={handleShare}
                                    text="Share"
                                />
                            ) : (
                                <div className=" tw-flex tw-justify-center tw-items-center tw-gap-1">
                                    {renderMeltHearts()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Sticky>
            {showModal === "dailyscore" && (
                <FlipDailyScoreModal
                    show={showModal === "dailyscore"}
                    handleClose={() => setShowModal("done")}
                    score={v3FlipState?.points || points}
                    actualPrice={formatPrice(v3FlipState?.properties[1]?.price)}
                    version={currentVersion}
                    property={properties[0]}
                    handleShare={handleShare}
                    onTryAgain={handleTryAgain}
                    puzzleResult={puzzleResult}
                />
            )}
        </motion.div>
    );
}

Flip.propTypes = {
    version: PropTypes.string.isRequired,
};
