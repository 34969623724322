import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/common/header/header";
import DailyProperties from "./pages/daily-properties/daily-properties";
import Footer from "./components/common/footer/footer";
import TermsOfService from "./pages/terms-of-service/terms-of-service";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import StartupScreen from "./pages/startup-screen/startup-screen";
import Leaderboard from "./pages/leaderboard/leaderboard";
import FAQ from "./pages/faq/faq";
import moment from "moment";
import { Toaster } from "react-hot-toast";
import config from "./config";
import { Auth0Provider } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";
import { isEmpty, isNil, isNull } from "lodash";
import { findOrCreateUser } from "./services/UserService";
import { createReferredUser } from "./services/ReferralService";
import { useUser } from "./context/UserContext";
import useLocalStorage from "./hooks/useLocalStorage";
import ProtectedRoute from "./components/common/protected-route/protected-route";
import HasCurrentAreaGuard from "./guards/has-current-area-guard";
import IsRegisteredUser from "./guards/is-registered-user-guard";
import { getAreaById } from "./services/AreaService";
import { useState } from "react";
import { checkVersion } from "version-rocket";
import { TOAST_MESSAGES } from "./lang/toast-messages.lang";
import RootModals from "./components/modals/root-modals/root-modals";
import NotFound from "./pages/not-found/not-found";
import MetaPixel from "./components/common/meta-pixel";
import ReferralWrapper from "./components/common/referral-wrapper";
import useGAEvent from "./hooks/useGAEvent";
import { AppContextProvider } from "./AppContextProvider";
import CreditsPage from "./pages/credits";
import HasSelectedLocation from "./guards/has-selected-location-guard";
import ChallengeMode from "./pages/ChallengeMode";
import ChallengeRedirect from "./pages/ChallengeRedirect";
import PlusMinus from "./pages/PlusMinus";
import GameRouter from "./pages/LiveVariant/components/GameRouter";
import ShortUrlRedirect from "./pages/LiveVariant/components/ShortUrlRedirect";
import { PLUS_MINUS_VERSIONS } from "./constants/plus-minus-versions";
import AnimationTest from "./components/animations/AnimationTest";
import ChooseGame from "./pages/ChooseGame";

const AUTH0_AUDIENCE = "https://dev-kb71sgmlh0p32db1.us.auth0.com/api/v2/";

const appVersion = require("../package.json").version;

/**
 * Icons for toast messages.
 *
 * @type {Object}
 * @property {JSX.Element} success - Success icon for toast messages.
 */
const toastIcons = {
    success: (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M12 1.875C6.417 1.875 1.875 6.417 1.875 12C1.875 17.583 6.417 22.125 12 22.125C17.583 22.125 22.125 17.583 22.125 12C22.125 6.417 17.583 1.875 12 1.875ZM12 19.875C7.658 19.875 4.125 16.342 4.125 12C4.125 7.658 7.658 4.125 12 4.125C16.342 4.125 19.875 7.658 19.875 12C19.875 16.342 16.342 19.875 12 19.875Z"
                fill="#0EBE1F"
            />
            <path
                d="M16.2051 8.20508L11.0001 13.4091L8.79508 11.2051L7.20508 12.7951L11.0001 16.5911L17.7951 9.79508L16.2051 8.20508Z"
                fill="#0EBE1F"
            />
        </svg>
    ),
};

function App() {
    const [isUserRegistered, setIsUserRegistered] = useState(false);
    const [value, updateValue, clearValue] = useLocalStorage("guest_gameplay"); // eslint-disable-line no-unused-vars
    const [visitorStatus, setVisitorStatus] = useLocalStorage("visitor-status");
    const [lastVisit, setLastVisit] = useLocalStorage("last-visit");
    const { userState, setUserState, setInvalidLogin, fetchUserData } = useUser();
    const { sendEvent } = useGAEvent();
    const isPuzzle = window.location.pathname.includes("/puzzle/");

    /**
     * Callback function for handling the redirect after a user logs in.
     * Any errors in this custom callback will result in an infinite redirect after login
     *
     * @param {Object} appState - The application state returned from the authentication process.
     *
     * @param {Object} user - The user object returned from the authentication process.
     */
    const onRedirectCallback = async (appState, user) => {
        // Clears the history state by removing the login code
        window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);

        // Add the current area from the local storage
        const formattedUserData = {
            ...user,
            version: appState?.version ?? "classic",
            current_area_id:
                isNil(userState?.current_area_id) || isEmpty(userState.current_area_id)
                    ? null
                    : userState.current_area_id,
        };

        const currentArea = isNull(formattedUserData.current_area_id)
            ? null
            : await getAreaById(userState?.current_area_id);

        findOrCreateUser(formattedUserData, value ?? null)
            .then((response) => {
                const { data } = response;

                if (data) {
                    setUserState(data.user, data.isUserCreated);

                    // It sets here the user notif settings
                    fetchUserData(data.user.id);

                    // If referral code exists, remove after user creation

                    if (data.isUserCreated) {
                        let referralCode = localStorage.getItem("referral_code");

                        // Send a GA event if there is an event object in the appState
                        if (!isNil(appState?.event)) {
                            const { referral_code, final_score } = appState?.event;

                            referralCode = referral_code;

                            sendEvent(appState?.event?.name, {
                                isAuthenticated: false,
                                referralCode: referral_code,
                                finalScore: final_score,
                                userRank: 0,
                            });
                        }

                        sendEvent("register", {
                            userArea: currentArea?.name ?? "None",
                            isAuthenticated: false,
                            referral_code: referralCode,
                            guestChallengeRedirect: userState?.guest_challenge_redirect,
                            challengeRegisterButton: userState?.challenge_register_button,
                            guestFinalScoreModalVersion: userState?.guest_final_score_modal_version,
                        });

                        // If referral code exists, create referred user instance and remove code after user creation
                        if (referralCode && !isEmpty(referralCode) && !isEmpty(data.user?.email_address)) {
                            createReferredUser({
                                referralId: referralCode,
                                userEmail: data.user.email_address,
                            });
                            localStorage.removeItem("referral_code");
                        }

                        window.rdt("track", "SignUp");

                        // For Meta Pixel tagging
                        setIsUserRegistered(true);

                        toast.success(`You're in! Welcome to PriceMe.`);
                    }

                    if (!isNil(data.user) && !data.isUserCreated) {
                        toast.success(`You're in! Welcome to PriceMe.`);
                    }

                    // Clear the guest gameplay data from the local storage since we already saved it to the database
                    if (value) {
                        clearValue();
                    }
                } else {
                    toast.error(`There was an error with retrieving the user from the server.`);
                }
            })
            .catch((error) => {
                setInvalidLogin(true);
                const errorMessage =
                    error.response?.data?.error ||
                    error.message ||
                    `Error logging in ${user.name}. Redirecting back...`;
                toast.error(errorMessage);
                console.error(error);
            });
    };

    // Initialize the check version service worker for polling the version
    checkVersion(
        {
            localPackageVersion: appVersion,
            originVersionFileUrl: `${window.location.origin}/version.json`,
        },
        {
            imageUrl: null,
            title: TOAST_MESSAGES.NEW_VERSION.TITLE,
            description: TOAST_MESSAGES.NEW_VERSION.DESCRIPTION,
            cancelButtonText: "Later",
            cancelMode: "ignore-current-window",
        }
    );

    useEffect(() => {
        const currentTime = moment.tz("America/Chicago");

        if (!lastVisit) {
            // If there's no last visit recorded, mark as "new" and set current time
            setVisitorStatus("new");
            setLastVisit(currentTime.toISOString()); // Store ISO format for consistency
        } else {
            // Parse the last visit time
            const lastVisitTime = moment.tz(lastVisit, "America/Chicago");

            // Calculate the difference in hours
            const hoursSinceLastVisit = currentTime.diff(lastVisitTime, "hours");

            if (hoursSinceLastVisit >= 24 && visitorStatus !== "repeat") {
                // If 24 hours have passed, and the status is not already "repeat", set as "repeat"
                setVisitorStatus("repeat");
                setLastVisit(currentTime.toISOString()); // Update the last visit time
            }
        }
    }, [lastVisit, visitorStatus, setVisitorStatus, setLastVisit]);

    return (
        <Auth0Provider
            domain={config.AUTH0.domain}
            clientId={config.AUTH0.client_id}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: AUTH0_AUDIENCE,
            }}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            onRedirectCallback={onRedirectCallback}
        >
            <div className="app-container">
                <BrowserRouter>
                    <MetaPixel isUserRegistered={isUserRegistered} />
                    <ReferralWrapper />
                    <AppContextProvider>
                        <Header />
                        <div className="app-content">
                            <Toaster
                                reverseOrder
                                position="top-center"
                                containerStyle={{
                                    top: isPuzzle ? "15svh" : "11svh",
                                    zIndex: 2,
                                    inset: `${isPuzzle ? "15svh" : "11svh"} 16px 16px`,
                                }}
                                toastOptions={{
                                    className: "custom-toast",
                                    duration: 5000,

                                    success: {
                                        className: "custom-toast custom-success-toast",
                                        icon: toastIcons.success,
                                    },

                                    error: {
                                        className: "custom-toast custom-error-toast",
                                    },
                                }}
                            />
                            <RootModals />
                            <Routes>
                                <Route path="/" element={<ChooseGame />} />

                                <Route
                                    path="/classic"
                                    element={
                                        <ProtectedRoute
                                            navigateData={{
                                                replace: false,
                                                to: "/classic",
                                                state: { failed_guard: true },
                                            }}
                                            guards={[IsRegisteredUser]}
                                        >
                                            <StartupScreen />
                                        </ProtectedRoute>
                                    }
                                ></Route>

                                <Route path="/test/animations" element={<AnimationTest />} />

                                <Route
                                    path="/daily-properties"
                                    element={
                                        <ProtectedRoute
                                            navigateData={{
                                                replace: false,
                                                to: "/classic",
                                                state: { failed_guard: true },
                                            }}
                                            guards={[HasSelectedLocation, HasCurrentAreaGuard]}
                                        >
                                            <DailyProperties />
                                        </ProtectedRoute>
                                    }
                                ></Route>

                                {Object.keys(PLUS_MINUS_VERSIONS).map((version) => (
                                    <Route
                                        path={`/plus-minus/${version}`}
                                        element={
                                            PLUS_MINUS_VERSIONS[version]?.component ? (
                                                PLUS_MINUS_VERSIONS[version].component
                                            ) : (
                                                <PlusMinus version={version} />
                                            )
                                        }
                                    />
                                ))}

                                {/* LIVE VARIANT ROUTES */}
                                <Route path="/:version" element={<GameRouter />} />
                                <Route path="/:version/:date/:propertyDetails" element={<GameRouter />} />
                                <Route path="/:version/:shortId" element={<ShortUrlRedirect />} />

                                <Route path="/challenge/:challenge_id" element={<ChallengeRedirect />} />
                                <Route path="/classic/challenge" element={<ChallengeMode />}></Route>
                                <Route path="/terms-of-service" element={<TermsOfService />}></Route>
                                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                                <Route path="/leaderboard" element={<Leaderboard />}></Route>
                                <Route path="/faq" element={<FAQ />}></Route>
                                <Route path="/credits" element={<CreditsPage />}></Route>
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </div>
                    </AppContextProvider>
                </BrowserRouter>
                <Footer />
            </div>
        </Auth0Provider>
    );
}

export default App;
