import React, { createContext, useState, useContext, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { findUser, findUserNotificationSetting } from "../services/UserService";
import uuid4 from "uuid4";
import { isNil, isNull } from "lodash";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [dbUser, setDbUser, clearDbUser] = useLocalStorage("user_data");
    const initialUser = {
        id: "",
        first_name: "",
        last_name: "",
        email_address: "",
        nickname: "",
        is_pro: false,
        show_startup_modal: true,
        show_score_leaderboard: true,
        notify_daily_property: true,
        notification_settings: [],
        createdAt: "",
        updatedAt: "",
        deletedAt: null,
        is_created: false,
        user_retrieve_error: false,
        current_area_id: "",
        current_location: "",
        show_scrollability_tooltip: false,
        use_email_account_picture: true,
        challenge_history_selected_user: { id: null, nickname: "" },
        leaderboard_active_tab: { tab: 0, button: 0 },
        guest_challenge_redirect: "other",
        challenge_register_button: "NA",
        guest_final_score_modal_version: "NA",
        leaderboardSelectedVersionIndex: 1,
    };

    const [userState, setUser] = useState(isNil(dbUser) ? initialUser : dbUser);
    const [isInvalidLogin, setInvalidLogin] = useState(false);
    let hasFetchedData = false;

    /**
     * Updates the user state and sets the 'is_created' property to indicate whether the user was newly created or not.
     *
     * @param {Object} user - The user object to be updated.
     * @param {boolean} isUserCreated - Indicates if the user was newly created (true) or not (false).
     * @param {Object} otherProps - Other user values to be updated.
     */
    const setUserState = (user, isUserCreated, otherProps = {}) => {
        // We will anticipate a null user object for guest users and generate a random uuid4 value to its user id
        if (isNull(user)) {
            user = { ...initialUser, id: uuid4() };
        }

        /**
         * The updated user data object with the 'is_created' property.
         * @type {Object}
         * @property {string} id - The user's ID.
         * @property {string} name - The user's name.
         * @property {string} email - The user's email address.
         * @property {boolean} is_created - Indicates if the user was newly created.
         * @property {...} otherProperties - Other properties of the user object.
         */
        const userData = { ...user, is_created: isUserCreated, ...otherProps };

        // Call the setDbUser function to update the user object in the state or context
        setDbUser(userData);
    };

    /**
     * Clears the user state by resetting it to the initial user state and clearing any stored user data from the database.
     *
     * @param {Object} initialUser - The initial user state to reset the user data to.
     */
    const clearUserState = () => {
        setUser(initialUser);
        clearDbUser();
    };

    /**
     * Fetches user data from the API
     *
     * @param {string|number} userId - The ID of the user to fetch data for.
     */
    const fetchUserData = async (userId) => {
        if (!hasFetchedData) {
            try {
                hasFetchedData = true;

                const fetchedUser = await findUser(userId);
                const fetchedUserNotifSetting = await findUserNotificationSetting(userId);

                // Update the local storage with the retrieved user data
                setDbUser({
                    ...(dbUser ?? {}),
                    ...fetchedUser,
                    notification_settings: fetchedUserNotifSetting,
                });
            } catch (error) {
                hasFetchedData = false;
                // Handle error if the API call fails
                console.error("Error fetching user data:", error);
                setInvalidLogin(true);
            }
        }
    };

    /**
     * Updates guest user current area id
     *
     * @param {string} areaId - The selected area id.
     */
    const updateGuestCurrentArea = (areaId) => {
        if (dbUser) {
            setDbUser({ ...dbUser, current_area_id: areaId });
        }
    };

    useEffect(() => {
        setUser(dbUser);
    }, [dbUser]);

    return (
        <UserContext.Provider
            value={{
                userState,
                isInvalidLogin,
                hasFetchedData,
                setUserState,
                clearUserState,
                setInvalidLogin,
                fetchUserData,
                updateGuestCurrentArea,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
