import React from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell, LabelList } from "recharts";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

const GuessDistributionChart = ({ guessDistribution, todaysScore }) => {
    const { isAuthenticated } = useAuth0();

    return (
        <div className={clsx(!isAuthenticated && "tw-opacity-60", "tw-w-full tw-px-4 tw-mb-1")}>
            <ResponsiveContainer width="100%" height={200}>
                <BarChart data={guessDistribution} layout="vertical" barGap={8}>
                    {/* Y-Axis for Labels (1-6) */}
                    <YAxis
                        dataKey="guess"
                        type="category"
                        tick={{ fill: "#100F13", fontSize: 20, fontFamily: "Noto Sans" }}
                        width={20}
                        tickLine={false}
                        axisLine={false}
                        interval={0} // Ensure all labels are shown (fix for 1, 3, 5 missing issue)
                    />
                    {/* X-Axis (Hidden) */}
                    <XAxis type="number" hide />
                    {/* Bars with labels inside */}
                    <Bar dataKey="count" barSize={25} radius={[0, 0, 0, 0]}>
                        {guessDistribution.map((entry, index) => {
                            return (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={todaysScore === index + 1 ? "#6C26C4" : "#D9D9D9"}
                                />
                            );
                        })}
                        <LabelList
                            dataKey="count"
                            content={({ x, y, width, value, index }) => {
                                const isZero = value === 0;
                                const barWidth = isZero ? 12 : width; // Small width for zero counts
                                const adjustedX = isZero ? x + 6 : x + width / 2; // Position label properly
                                return (
                                    <g>
                                        <rect
                                            x={x}
                                            y={y}
                                            width={barWidth}
                                            height={25}
                                            fill={todaysScore === index + 1 ? "#6C26C4" : "#D9D9D9"}
                                        />
                                        <text
                                            x={adjustedX}
                                            y={y}
                                            dy={18}
                                            fill={todaysScore === index + 1 && value ? "#FFFFFF" : "#100F13"}
                                            fontSize={14}
                                            fontWeight="bold"
                                            textAnchor="middle"
                                        >
                                            {isAuthenticated ? value : "?"}
                                        </text>
                                    </g>
                                );
                            }}
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default GuessDistributionChart;
