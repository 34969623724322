import React, { useRef, useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { get, inRange, isEmpty, find, isEqual, isNil } from "lodash";
import { useSwipeable } from "react-swipeable";
import { subscribe, isSupported } from "on-screen-keyboard-detector";
import { toast } from "react-hot-toast";
import Sticky from "react-sticky-el";
import { useAuth0 } from "@auth0/auth0-react";
import MoonLoader from "react-spinners/MoonLoader";

import PropertyInfo from "../../../../components/common/PropertyInfo";
import { LIVE_VARIANT_VERSIONS } from "../../../../constants/live-variant-versions";
import { getLiveVariantDailyProperty } from "../../../../services/PropertyService";
import { getUserPuzzleStats, getUserGuess, submitGuess } from "../../../../services/UserService";
import useGAEvent from "../../../../hooks/useGAEvent";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import useModalState from "../../../../hooks/useModalState";

import { IoChevronUp } from "react-icons/io5";
import { motion } from "framer-motion";
import TileRow from "./TileRow";
import { formatPrice, pxToRem, validateImageURL } from "../../../../helpers/helpers";
import CustomButton from "../../../../components/common/buttons/custom-button/custom-button";

import PuzzleDailyScoreModal from "./PuzzleDailyScoreModal";
import FullListingModal from "./FullListingModal";
import ArchiveSelectModal from "./ArchiveSelectModal";
import config from "../../../../config";
import moment from "moment";
import { triggerShare } from "../../../../helpers/triggerShare";
import { isMobile } from "react-device-detect";
import useVisualViewport from "../../../../hooks/useVisualViewport";
import { useLiveVariant } from "../../../../context/LiveVariantContext";
import { useUser } from "../../../../context/UserContext";

const MAX_DIGIT = 8;
const MAX_GUESS = 6;
const INCORRECT_THRESHOLD = 2;
const INITIAL_GUESS_INPUT_HEIGHT = 6.5;

const GAME_STATES = Object.freeze({ ONGOING: "ONGOING", LOSE: "LOSE", WIN: "WIN" });

const scoreEmojis = {
    correct: "🟪",
    incorrect: "⬜",
    close: "🟧",
};

const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const SEARCH_URL = "https://www.realtor.com/realestateandhomes-search";

export default function Puzzle({ version }) {
    const { sendEvent } = useGAEvent();
    const { setModalId } = useModalState();
    const { isAuthenticated } = useAuth0();
    const { userState } = useUser();
    const { liveVariantState, setLiveVariantState } = useLiveVariant();
    const [shownHelpModals, setShownHelpModal] = useLocalStorage("shown-help-modals", []);
    const viewport = useVisualViewport();
    const bodyRef = useRef(null);
    const inputRef = useRef(null);
    const [property, setProperty] = useState(null);
    const [guesses, setGuesses] = useState(Array(MAX_GUESS).fill(null));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expand, setExpand] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [showModal, setShowModal] = useState("");
    const [gameState, setGameState] = useState(GAME_STATES.ONGOING);
    const [ongoingAnimation, setOngoingAnimation] = useState("idle");
    const [guessInputHeight, setGuessInputHeight] = useState();
    const [virtualKeyboardShown, setVirtualKeyboardShown] = useState(false);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showFullListingModal, setShowFullListingModal] = useState(false);
    const [isPhotosVerified, setIsPhotosVerified] = useState(false);
    const [isToastDisplayed, setIsToastDisplayed] = useState(false); // Prevent multiple toast appearing
    const [show404, setShow404] = useState(false);
    const [isSavingUserGuess, setIsSavingUserGuess] = useState(false);
    const currentVersion = LIVE_VARIANT_VERSIONS[version];
    const { date, propertyDetails } = useParams();
    let variant_link = `${date}/${propertyDetails}`;

    if (window.location?.hash) {
        variant_link += window.location.hash; // Append the hash fragment
    }
    const [puzzleShareObject, setPuzzleShareObject] = useLocalStorage("puzzle_share_object", {});
    const [guessedPropertiesLocal, setGuessedProperties] = useLocalStorage("guessed_properties", []);
    const [guessedPropertyResultsLocal, setGuessedPropertyResults] = useLocalStorage(
        "guessed_property_results",
        []
    );
    const [puzzleTooltipData, setPuzzleTooltipData] = useLocalStorage("puzzle-tooltip-data", {});
    const [visitorStatus] = useLocalStorage("visitor-status");

    const dailyPropertyGuess = useQuery({
        queryKey: [
            "puzzle_daily_property_guess",
            property?.id,
            userState?.id,
            liveVariantState?.playablePropertyId,
        ],
        queryFn: () =>
            getUserGuess(
                userState?.id,
                property?.id,
                null,
                null,
                liveVariantState?.playablePropertyId,
                "puzzle"
            ),
        cacheTime: 0,
        enabled: isAuthenticated && !isNil(userState?.id) && !isNil(property?.id),
        placeholderData: () => {
            return {
                guess: guesses,
            };
        },
    });

    const puzzleGuessMutation = useMutation({
        mutationFn: (userGuess) => {
            return submitGuess(
                userState?.id,
                property?.id,
                userGuess,
                null,
                null,
                liveVariantState.shortLink,
                "puzzle",
                Boolean(!liveVariantState?.same_with_current_date) // is_archive_play
            );
        },
        onSuccess: () => {
            // Refetch the daily property guess after a successful mutation
            dailyPropertyGuess.refetch();
        },
    });

    const formattedGuesses = (
        dailyPropertyGuess?.data?.guess?.map((item) =>
            item?.guess ? String(item.guess).padStart(8, "0") : null
        ) || []
    )
        .concat(Array(6).fill(null))
        .slice(0, 6);

    const guessedResult = isAuthenticated
        ? {
              guesses: formattedGuesses,
              currentIndex: dailyPropertyGuess?.data?.guess?.length ?? 0,
          }
        : find(
              guessedPropertyResultsLocal,
              (result) => result.property.mls_id === property?.mls_id && result?.version === version
          );

    // Check if this property instance has already been guessed
    const isPropertyGuessed = isAuthenticated
        ? dailyPropertyGuess?.data?.guess?.length
        : guessedPropertiesLocal?.includes(property?.mls_id) && guessedResult;

    const dailyProperties = useQuery({
        queryKey: ["live-variant-daily-property", variant_link],
        queryFn: () => getLiveVariantDailyProperty({ variant_link }),
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.properties", null);
            if (property?.price) {
                setProperty(property);
            }
        },
    });

    const userPuzzleStats = useQuery({
        queryKey: ["puzzle-user-stats", isAuthenticated, userState?.id, showModal],
        queryFn: () => getUserPuzzleStats(userState?.id),
        enabled: isAuthenticated && showModal === "dailyscore",
    });

    const isLoading =
        dailyProperties?.isLoading || dailyProperties?.isFetching || !property || !isPhotosVerified;

    const url = `${SEARCH_URL}/${property?.zip_code}/price-${property?.price}-${property?.price}`;
    const actualPriceValue = property?.price;
    const actualPriceSplit = useMemo(() => {
        return actualPriceValue ? actualPriceValue.toString().padStart(MAX_DIGIT, "0").split("") : [];
    }, [actualPriceValue]);

    const correctGuessIndex = guesses.findIndex((guess) => +guess === actualPriceValue);
    const validGuesses = guesses.filter((guess) => !isEmpty(guess));
    const score = (correctGuessIndex !== -1 ? correctGuessIndex : validGuesses?.length) + 1;
    const isPuzzleLose = score > 6;
    const checkTileMode = useCallback(
        (rowIndex, tileIndex, tileValue) => {
            if (rowIndex === currentIndex || isEmpty(tileValue)) {
                return "default";
            }

            const correctTileValue = +actualPriceSplit[tileIndex];

            if (correctTileValue === +tileValue) {
                return "correct";
            }

            const inThreshold = inRange(
                +tileValue,
                correctTileValue - INCORRECT_THRESHOLD,
                correctTileValue + INCORRECT_THRESHOLD + 1
            );

            return inThreshold ? "close" : "incorrect";
        },
        [actualPriceSplit, currentIndex] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const puzzleResult = validGuesses.map((guessValue, guessIndex) => {
        return (
            <div className="tw-text-white tw-tracking-[-2px]">
                {String(guessValue)
                    .split("")
                    .map(
                        (digitValue, digitIndex) =>
                            scoreEmojis[checkTileMode(guessIndex, digitIndex, digitValue)] +
                            (digitIndex === 1 || digitIndex === 4 ? "," : "")
                    )
                    .join("")}
            </div>
        );
    });

    const swipeHandlers = useSwipeable({
        onSwipedUp: () => !expand && setExpand(true),
        onSwipedDown: () => {
            // When swiping down on the expanded guess input section, remove focus from the input and set expand to false
            if (expand) {
                inputRef?.current?.blur();
                setExpand(false);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true, // Optional: enables swipe events with mouse
    });

    const handleChange = (event) => {
        if (ongoingAnimation !== "idle" || gameState !== GAME_STATES.ONGOING) {
            event.preventDefault();

            return;
        }

        const value = event.target.value;

        setGuesses((prevGuesses) => {
            const newGuesses = [...prevGuesses];
            newGuesses[currentIndex] = value;
            return newGuesses;
        });
    };

    const handleEnterKey = (event) => {
        if (ongoingAnimation !== "idle" || gameState !== GAME_STATES.ONGOING) {
            event.preventDefault();

            return;
        }

        const currentGuessLength = guesses[currentIndex]?.length ?? 0;

        if (event.code === "Enter") {
            event.preventDefault();

            if (currentGuessLength === MAX_DIGIT) {
                handleSubmit(event);
            }
        }

        // This is a fix for PM-1689 since Android Chrome has a different placement of the key code
        const keyCode = event.code || event.nativeEvent.key;

        if (currentGuessLength >= MAX_DIGIT && keyCode !== "Backspace") {
            event.preventDefault();
            return;
        }
    };

    const handleSubmit = (event) => {
        sendEvent("enter_guess", {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });

        setIsSubmitting(true);

        // Set the ongoing animation to "transit" to prep before the next animation so that this is not "idle"
        setOngoingAnimation("transit");

        setCurrentIndex((prevIndex) => prevIndex + 1);
        event.target.value = "";
        inputRef.current?.focus(); // Refocus input to keep keyboard open
    };

    const generateShareDetails = useCallback(() => {
        const currentDate = moment(liveVariantState.property_play_date).format("MMM D, YYYY");

        return {
            shareTextTitle: `PriceMe ${currentDate}\n`,
            shareUrl: `${config.BASE_URL}/${currentVersion.url}/${liveVariantState?.shortLink}?utm_medium=share`,
            shareTextBody: validGuesses
                .map((guessValue, guessIndex) => {
                    return String(guessValue)
                        .split("")
                        .map(
                            (digitValue, digitIndex) =>
                                scoreEmojis[checkTileMode(guessIndex, digitIndex, digitValue)] +
                                (digitIndex === 1 || digitIndex === 4 ? "," : "")
                        )
                        .join("");
                })
                .join("\n"),
        };
    }, [
        checkTileMode,
        currentVersion?.url,
        liveVariantState?.property_play_date,
        liveVariantState?.shortLink,
        validGuesses,
    ]);

    const handleShare = async (event, skipToast) => {
        sendEvent("daily_score_share_score", {
            userScore: validGuesses.length ?? 0,
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });

        const { shareTextTitle, shareTextBody, shareUrl } = generateShareDetails();

        await triggerShare({
            web: {
                text: `${shareTextTitle}${shareTextBody}\n\n${shareUrl}`,
            },
            mobile: {
                title: shareTextTitle,
                text: shareTextBody,
                url: shareUrl,
            },
            skipToast,
        });

        setShowModal("done");
    };

    const handleShowFullListing = () => {
        sendEvent("see_full_listing_daily_property", {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });

        if (isPropertyGuessed) {
            window.open(url, "_blank");
        } else {
            setShowFullListingModal(true);
        }
    };

    const handleFullListingModalClicks = (eventName) => {
        sendEvent(eventName, {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus: visitorStatus || "new",
        });

        if (eventName === "back_to_guess_price") {
            setShowFullListingModal(false);
        }

        if (eventName === "see_full_listing_modal") {
            window.open(url, "_blank");
        }
    };

    const resetGameState = () => {
        setProperty(null);
        setExpand(false);
        setGuesses(Array(MAX_GUESS).fill(null));
        setCurrentIndex(0);
        setGameState(GAME_STATES.ONGOING);
        setIsPhotosVerified(false);
        setShow404(false);
        setIsSavingUserGuess(false);
        setIsToastDisplayed(false);
        toast.dismiss();
    };

    const getScoreFeedback = (score) => {
        switch (score) {
            case 1:
                return "Unbelievable!";
            case 2:
                return "Uncanny!";
            case 3:
                return "Wow! Strong performance!";
            case 4:
                return "Well done";
            case 5:
                return "Solid effort today";
            case 6:
                return "Phew!";
            default:
                return `${formatPrice(actualPriceValue)} \n Try again tomorrow`;
        }
    };

    // Check property status and validate images
    useEffect(() => {
        if (!property) return;

        const { status_text, image_urls } = property;
        const nonActiveStatusRoots = ["cancel", "expire", "close"];

        const isNonActiveStatus = nonActiveStatusRoots.some((status) =>
            (status_text || "").toLowerCase().trim().includes(status)
        );

        // Check if listing is cancelled, expired, closed, or if photos are unavailable
        if (isNonActiveStatus || !image_urls || image_urls.length === 0) {
            setShow404(true);
            return;
        }

        if (!isPhotosVerified) {
            // Validate image URLs if photos are available
            Promise.all(image_urls.map(validateImageURL)).then((results) => {
                const validUrls = results.filter((result) => result.isValid).map((result) => result.url);

                if (isEmpty(validUrls)) setShow404(true);

                setProperty({ ...property, image_urls: validUrls });
                setIsPhotosVerified(true);
            });
        }
    }, [property, isPhotosVerified]);

    useEffect(() => {
        // Show the modal if the game state has been updated from "ONGOING" and there's no ongoing animation ("idle")
        if (gameState !== GAME_STATES.ONGOING && ongoingAnimation === "idle") {
            if (!guessedPropertiesLocal?.includes(property?.mls_id)) {
                setGuessedProperties([...guessedPropertiesLocal, property.mls_id]);
            }

            // Store the result in guessed_property_results
            setGuessedPropertyResults([
                ...guessedPropertyResultsLocal,
                { property, guesses, currentIndex, version },
            ]);

            if (isAuthenticated) {
                puzzleGuessMutation.mutate({
                    guess: validGuesses.map((guess) => parseInt(guess)),
                    score: score,
                    area_id: userState?.current_area_id,
                });
                setIsSavingUserGuess(true);
            }

            const scoreFeedback = getScoreFeedback(score);
            toast(scoreFeedback, { duration: isPuzzleLose ? Infinity : 1500 });
            setIsToastDisplayed(true);
            setInputFocused(false); // Remove the input focus after the game (fixes https://app.asana.com/0/1204535088114623/1208937585206254/f)

            setTimeout(() => {
                setShowModal("dailyscore");
            }, 3000);
        }
    }, [gameState, ongoingAnimation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPropertyGuessed && !isToastDisplayed && !isLoading && isPuzzleLose) {
            toast(getScoreFeedback(score), { duration: Infinity });
            setIsToastDisplayed(true);
        }

        if (!isPropertyGuessed && !isSavingUserGuess) {
            toast.dismiss();
            setIsToastDisplayed(false);
        }
    }, [isPropertyGuessed, isToastDisplayed, isLoading, isSavingUserGuess]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPropertyGuessed && guessedResult?.guesses && !isEqual(guesses, guessedResult.guesses)) {
            setGuesses(guessedResult.guesses);
            setCurrentIndex(guessedResult.currentIndex);
        }
    }, [guessedResult?.guesses, isPropertyGuessed]); // eslint-disable-line react-hooks/exhaustive-deps

    // Toggle the scrolling if the guess input is focused or blurred
    useEffect(() => {
        if (inputFocused) {
            document.body.style.overflow = "hidden";

            if (isMobile) {
                document.body.style.touchAction = "none";
            }
        } else {
            document.body.style.overflow = "";

            if (isMobile) {
                document.body.style.touchAction = "";
            }
        }
    }, [inputFocused]);

    useEffect(() => {
        if (!dailyProperties?.isLoading && !dailyProperties?.isFetching && !property) {
            // try to refetch when property is not yet set
            dailyProperties.refetch();
        }
    }, [property]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Function to handle viewport resize issues for iOS
        const handleResize = () => {
            const currentViewportHeight = window.innerHeight;
            setVirtualKeyboardShown(currentViewportHeight < viewportHeight);
            setViewportHeight(currentViewportHeight);
        };

        const handleKeyboardChange = (isKeyboardOpen) => {
            let keyboardState = false;

            if (isNil(isKeyboardOpen)) {
                keyboardState = false; // Default to false when it's null or undefined
            } else if (isKeyboardOpen === true || isKeyboardOpen === "visible") {
                keyboardState = true; // Keyboard is visible
            } else if (isKeyboardOpen === false || isKeyboardOpen === "hidden") {
                keyboardState = false; // Keyboard is hidden
            }

            setVirtualKeyboardShown(keyboardState);
        };

        // Add a keyboard detector if supported
        let unsubscribe;
        if (isSupported()) {
            unsubscribe = subscribe((isKeyboardOpen) => {
                handleKeyboardChange(isKeyboardOpen);
            });
        }

        // iOS-specific resize handling / quirks
        if (isIOS() && !isSupported()) {
            window.addEventListener("resize", handleResize);
        }

        // Cleanup
        return () => {
            if (unsubscribe) unsubscribe();
            if (isIOS()) {
                window.removeEventListener("resize", handleResize);
            }
        };
    }, [viewportHeight]);

    useEffect(() => {
        // There is a weird delay in the viewport resize event in IOS when the virtual keyboard is opened after the page load
        // This delay goes away when the user scrolls the page
        // https://stackoverflow.com/questions/72747030/delay-on-resize-event-on-virtual-keyboard-on-ios-safari
        setGuessInputHeight(
            expand
                ? (isPropertyGuessed ? 22 : 19) +
                      0.5 +
                      (virtualKeyboardShown ? pxToRem(viewport.offsetHeight) : 0)
                : INITIAL_GUESS_INPUT_HEIGHT
        );
    }, [viewport?.offsetHeight, expand, isPropertyGuessed, virtualKeyboardShown]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isLoading && !shownHelpModals?.includes(version) && !show404) {
            setShownHelpModal([...shownHelpModals, version]);
            setModalId("LIVE_VARIANT_HELP_MODAL");
        }
    }, [isLoading, shownHelpModals, show404]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (property) {
            sendEvent("start_page_load", {
                liveVariantVersion: currentVersion?.gaVersion,
                visitorStatus: visitorStatus || "new",
            });
        }
    }, [property]); // eslint-disable-line react-hooks/exhaustive-deps

    // This will prevent shrinking the guess input container if there's an ongoing animation or tooltip is still shown
    useEffect(() => {
        const noTooltipShown =
            !isEmpty(puzzleTooltipData) &&
            puzzleTooltipData?.correct?.hidden !== false &&
            puzzleTooltipData?.close?.hidden !== false;

        if (isSubmitting && noTooltipShown && ongoingAnimation === "idle") {
            const isCorrect = Number(guesses[currentIndex - 1]) === actualPriceValue;
            const noMoreGuess = currentIndex === guesses.length;

            if (isCorrect) {
                setGameState(GAME_STATES.WIN);
            }

            if (noMoreGuess) {
                setGameState(GAME_STATES.LOSE);
            }

            setIsSubmitting(false);
        }
    }, [puzzleTooltipData, ongoingAnimation, isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (property && !isLoading && !show404) {
            setLiveVariantState({
                ...liveVariantState,
                show_date_picker_tooltip: !liveVariantState.same_with_current_date,
            });
        }
    }, [property, isLoading, show404]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPropertyGuessed && !isEmpty(validGuesses)) {
            const dateToday = moment.tz(new Date(), "America/Chicago").format("YYYY-MM-DD");
            const shareDetails = generateShareDetails();

            if (
                liveVariantState?.property_play_date === dateToday &&
                !isEqual(puzzleShareObject, shareDetails)
            ) {
                setPuzzleShareObject(shareDetails);
            }
        }
    }, [
        isAuthenticated,
        isPropertyGuessed,
        liveVariantState?.property_play_date,
        puzzleShareObject,
        validGuesses,
        generateShareDetails,
        setPuzzleShareObject,
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    if (dailyProperties?.error) {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4 tw-p-8">
                {dailyProperties.error.response?.data?.message ||
                    "Failed to load daily property. Please try again later."}
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4">
                <MoonLoader size={40} color="#63c19f" />
            </div>
        );
    }

    if (show404) {
        return (
            <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-m-10">
                <h2>Oops.</h2>
                <p className="tw-max-w-[600px]">
                    Sometimes a listing agent updates a property after it sells, and we can't show it anymore.
                    This seems to be the case now.{" "}
                    <span
                        className="tw-text-blue-500 tw-underline tw-cursor-pointer"
                        onClick={() => {
                            setLiveVariantState({ ...liveVariantState, show_archive_select_modal: true });
                        }}
                    >
                        Click here
                    </span>{" "}
                    to pick a different day.
                </p>

                <ArchiveSelectModal
                    score={score}
                    version={currentVersion}
                    visitorStatus={visitorStatus}
                    resetGameState={resetGameState}
                />
            </div>
        );
    }

    return (
        <div className="property-container" ref={bodyRef}>
            <PropertyInfo property={property} showFullListing={handleShowFullListing} />
            <Sticky
                className="tw-sticky tw-bottom-0 tw-z-[97]"
                mode="bottom"
                positionRecheckInterval={expand ? 50 : 2}
                onClick={() => {
                    if (!expand) {
                        setInputFocused(true);
                        inputRef.current?.focus();
                    }
                    setExpand(true);
                }}
            >
                <motion.div
                    className="!tw-bg-[#2E2E2E] guess-input-container overflow-hidden px-3 pt-0 pb-3 guess-input-container-shadow"
                    {...swipeHandlers} // Attach swipe handlers here
                    initial={{ height: `${INITIAL_GUESS_INPUT_HEIGHT}rem` }}
                    animate={{
                        height: `${guessInputHeight}rem`,
                    }}
                    transition={{
                        height: {
                            ease: "easeOut",
                            duration: 0.5,
                            delay: virtualKeyboardShown ? 0 : 0.1,
                        },
                    }}
                >
                    <div className="flex-wrap d-flex align-items-center justify-content-center">
                        <motion.div
                            className="score-to-beat mw-100 px-4"
                            initial={false}
                            animate={{ rotate: expand ? 180 : 0 }}
                            transition={{ ease: "easeOut", duration: 0.5, delay: 0.1 }}
                        >
                            <IoChevronUp
                                className="hover:tw-cursor-pointer tw-text-[#D9D9D9]"
                                fontSize={25}
                                onClick={(e) => {
                                    // We need to stop the propagation to the Sticky component's onClick handler if expanded
                                    if (expand) {
                                        e.stopPropagation();
                                    }

                                    setExpand(!expand);
                                }}
                            />
                        </motion.div>
                    </div>
                    <p className="tw-font-bold tw-text-center tw-text-[#FAB85F]">
                        {expand
                            ? "Hide guesses"
                            : `Tap to ${!isPropertyGuessed ? "guess the price" : "review your game"}`}
                    </p>
                    <div className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-center tw-gap-1">
                        {guesses.map((guess, index) => (
                            <TileRow
                                key={`tile-row-${index}`}
                                expand={expand}
                                length={MAX_DIGIT}
                                rowIndex={index}
                                value={guess}
                                gameIndex={currentIndex}
                                checkTileMode={checkTileMode}
                                inputRef={inputRef}
                                handleSubmit={handleSubmit}
                                setOngoingAnimation={setOngoingAnimation}
                                ongoingAnimation={ongoingAnimation}
                                isPropertyGuessed={isPropertyGuessed}
                                puzzleTooltipData={puzzleTooltipData}
                                setPuzzleTooltipData={setPuzzleTooltipData}
                            />
                        ))}

                        <input
                            type="number"
                            inputMode="numeric"
                            className="tw-opacity-0 tw-fixed tw--left-[2000px]"
                            disabled={isPropertyGuessed || gameState !== GAME_STATES.ONGOING}
                            ref={inputRef}
                            value={guesses[currentIndex] || ""}
                            maxLength={MAX_DIGIT}
                            onChange={handleChange}
                            onKeyDown={handleEnterKey}
                            onFocus={() => setInputFocused(true)}
                            onBlur={() => setInputFocused(false)}
                        />
                    </div>

                    {isPropertyGuessed && expand && (
                        <div className="tw-flex tw-gap-4 !tw-pt-2 !tw-px-4">
                            <CustomButton
                                className="tw-w-1/2 tw-flex tw-items-center tw-justify-center white-outlined tw-rounded-500px"
                                handleClick={() => setShowModal("dailyscore")}
                                text="Done"
                            />
                            <CustomButton
                                className="tw-w-1/2 tw-flex tw-items-center tw-justify-center gold-solid tw-rounded-500px"
                                handleClick={() => handleShare()}
                                text="Share Score"
                            />
                        </div>
                    )}
                </motion.div>
            </Sticky>
            {showModal === "dailyscore" &&
                (!isAuthenticated || !isNil(userPuzzleStats?.data?.data?.currentStreak)) && (
                    <PuzzleDailyScoreModal
                        show={showModal === "dailyscore"}
                        handleClose={() => setShowModal("done")}
                        score={score}
                        actualPrice={formatPrice(actualPriceValue)}
                        version={currentVersion}
                        property={property}
                        handleShare={handleShare}
                        puzzleResult={puzzleResult}
                        streak={userPuzzleStats?.data?.data?.currentStreak ?? 0}
                    />
                )}
            {showFullListingModal && (
                <FullListingModal show={showFullListingModal} onClick={handleFullListingModalClicks} />
            )}

            <ArchiveSelectModal
                score={score}
                version={currentVersion}
                visitorStatus={visitorStatus}
                resetGameState={resetGameState}
            />
        </div>
    );
}

Puzzle.propTypes = {
    version: PropTypes.string.isRequired,
};
