import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NotFound from "./NotFound";
import { resolveLink } from "../../../services/PropertyService";
import { useLiveVariant } from "../../../context/LiveVariantContext";

const ShortUrlRedirect = () => {
    const { version, shortId } = useParams();
    const { liveVariantState, setLiveVariantState } = useLiveVariant();
    const navigate = useNavigate();
    const [isRedirecting, setIsRedirecting] = useState(true);

    useEffect(() => {
        const fetchLongLink = async () => {
            try {
                const response = await resolveLink({ short_link: shortId });
                const longLink = response?.data?.property?.long_link;
                const shortLink = response?.data?.property?.short_link;

                if (shortLink) {
                    setLiveVariantState({
                        ...liveVariantState,
                        shortLink,
                        playablePropertyId: response?.data?.property?.id,
                    });
                }

                if (longLink) {
                    navigate(`/${version}${longLink}`, { replace: true });
                    return;
                }
            } catch (error) {
                console.error("Error resolving short link:", error);
            }
            setIsRedirecting(false);
        };

        fetchLongLink();
    }, [shortId, version]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isRedirecting) return null;

    return <NotFound />;
};

export default ShortUrlRedirect;
