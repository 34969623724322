import React, { useEffect, createContext, useState, useContext } from "react";
import moment from "moment-timezone";

const LiveVariantContext = createContext();

const currentDate = moment.tz(new Date(), "America/Chicago").format("YYYY-MM-DD");

export const LiveVariantProvider = ({ children }) => {
    const initialLiveVariantState = {
        version: "",
        property_play_date: currentDate,
        show_archive_select_modal: false,
        show_date_picker_tooltip: false,
        same_with_current_date: false,
        shortLink: null,
        playablePropertyId: null,
    };

    const [liveVariantState, setLiveVariantState] = useState(initialLiveVariantState);

    useEffect(() => {
        setLiveVariantState({
            ...liveVariantState,
            same_with_current_date: liveVariantState.property_play_date === currentDate,
        });
    }, [liveVariantState.property_play_date]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LiveVariantContext.Provider
            value={{
                liveVariantState,
                setLiveVariantState,
            }}
        >
            {children}
        </LiveVariantContext.Provider>
    );
};

export const useLiveVariant = () => useContext(LiveVariantContext);
