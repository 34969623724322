import React, { memo, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Tile from "./Tile";
import BtnSubmit from "./BtnSubmit";
import { isEqual, isEmpty } from "lodash";
import { motion, AnimatePresence } from "framer-motion";
import { FocusProvider } from "./FocusContext";
import CustomTooltip from "../../../../components/common/CustomTooltip";
import useModalState from "../../../../hooks/useModalState";

const ANIMATION_DELAY_COEFFICIENT = 0.5;

/**
 * Generates a tooltip message for "close" tile mode based on the given value and range.
 *
 * @param {number} savedColumnValue - The value of the current column.
 * @param {number} range - The range to calculate around the saved value (default is 2).
 * @param {number} [min=0] - The minimum allowable value (default is 0).
 * @param {number} [max=9] - The maximum allowable value (default is 9).
 * @returns {string} - The formatted tooltip message.
 */
const generateCloseTooltipMessage = (savedColumnValue, range = 2, min = 0, max = 9) => {
    const withinRange = [];

    for (let delta = -range; delta <= range; delta++) {
        const value = savedColumnValue + delta;

        // Only include values strictly within the min-max range and not the savedColumnValue itself
        if (value >= min && value <= max && value !== savedColumnValue) {
            withinRange.push(value);
        }
    }

    const formattedRange = withinRange
        .map((num, index) => {
            if (index === withinRange.length - 1 && withinRange.length > 1) {
                return `or ${num}`;
            }
            return `${num}`;
        })
        .join(withinRange.length > 2 ? ", " : " ");

    return `Close! Orange means you're within ${range}. So this digit is either ${formattedRange}.`;
};

const TileRow = memo(
    ({
        rowIndex,
        length,
        expand,
        gameIndex,
        value,
        checkTileMode,
        inputRef,
        handleSubmit,
        setOngoingAnimation,
        isPropertyGuessed,
        puzzleTooltipData,
        setPuzzleTooltipData,
        ongoingAnimation,
    }) => {
        const [doneAnimate, setDoneAnimate] = useState(false);
        const { setModalId } = useModalState();
        const rowValue = useMemo(() => {
            const guessValue = value ? value.split("") : [];

            return Array.from({ length }, (_, i) => guessValue[i] || "");
        }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

        const showRow = expand || rowIndex === 0 || (!expand && rowIndex <= gameIndex);

        // Determine the next available index dynamically
        const nextAvailableIndex = useMemo(() => {
            return rowValue.findIndex((val) => val === "") !== -1
                ? rowValue.findIndex((val) => val === "")
                : length;
        }, [rowValue]); // eslint-disable-line react-hooks/exhaustive-deps

        let hasCorrect = false;
        let hasClose = false;

        const renderTooltip = (columnIndex, columnValue) => {
            if (
                (hasClose && hasCorrect) ||
                (puzzleTooltipData?.close?.hidden && puzzleTooltipData?.correct?.hidden)
            ) {
                return null;
            }

            const currentIndexes = { rowIndex, columnIndex };

            const tileMode = checkTileMode(rowIndex, columnIndex, columnValue);
            let customContent;

            switch (tileMode) {
                case "correct":
                    const correctIndexes = {
                        rowIndex: puzzleTooltipData?.correct?.rowIndex,
                        columnIndex: puzzleTooltipData?.correct?.columnIndex,
                    };

                    if (isEqual(currentIndexes, correctIndexes) && !puzzleTooltipData?.correct?.hidden) {
                        customContent = (
                            <>
                                <p className="tw-mb-1">Bingo! Correct digits turn purple.</p>
                                <p
                                    className="tw-underline tw-mb-1"
                                    onClick={() => setModalId("LIVE_VARIANT_HELP_MODAL")}
                                >
                                    See instructions
                                </p>
                            </>
                        );
                    }

                    // Only set the puzzleTooltipData for "correct" once
                    if (!hasCorrect && !puzzleTooltipData?.correct) {
                        hasCorrect = true;
                        setPuzzleTooltipData({
                            ...puzzleTooltipData,
                            correct: {
                                ...currentIndexes,
                                columnValue,
                                hidden: false,
                            },
                        });
                    }
                    break;

                case "close":
                    const closeIndexes = {
                        rowIndex: puzzleTooltipData?.close?.rowIndex,
                        columnIndex: puzzleTooltipData?.close?.columnIndex,
                    };
                    const savedColumnValue = puzzleTooltipData?.close?.columnValue ?? 0;

                    // Don't show close tooltip when correct tooltip is not yet hidden
                    if (
                        isEqual(currentIndexes, closeIndexes) &&
                        (!puzzleTooltipData?.correct || puzzleTooltipData?.correct?.hidden) &&
                        !puzzleTooltipData?.close?.hidden
                    ) {
                        customContent = (
                            <>
                                <p className="tw-mb-1">
                                    {generateCloseTooltipMessage(Number(savedColumnValue))}
                                </p>
                                <p
                                    className="tw-underline tw-mb-1"
                                    onClick={() => setModalId("LIVE_VARIANT_HELP_MODAL")}
                                >
                                    See instructions
                                </p>
                            </>
                        );
                    }

                    // Only set the puzzleTooltipData for "close" once
                    if (!hasClose && !puzzleTooltipData?.close) {
                        hasClose = true;
                        setPuzzleTooltipData({
                            ...puzzleTooltipData,
                            close: {
                                ...currentIndexes,
                                columnValue,
                                hidden: false,
                            },
                        });
                    }
                    break;

                default:
                    return null;
            }

            if (!customContent || !doneAnimate) return null;

            return (
                <CustomTooltip
                    id={`tile-tooltip-${rowIndex}-${columnIndex}`}
                    customContent={customContent}
                    open
                    onClose={() => {
                        setModalId("");
                        setPuzzleTooltipData({
                            ...puzzleTooltipData,
                            [`${tileMode}`]: {
                                ...puzzleTooltipData[`${tileMode}`],
                                hidden: true,
                            },
                        });
                        inputRef.current?.focus(); // Refocus input to keep keyboard open
                    }}
                    autoCloseTime={3000}
                    animate
                    placement="top"
                />
            );
        };

        return (
            <AnimatePresence>
                {/* Render the first TileRow when its not yet expanded */}
                {showRow && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ ease: "easeOut", duration: 0.3 }}
                        className="tw-flex tw-flex-row tw-flex-wrap tw-items-center tw-justify-center tw-gap-1"
                    >
                        <FocusProvider>
                            {rowValue.map((columnValue, columnIndex) => {
                                const isFocused =
                                    columnIndex === nextAvailableIndex &&
                                    rowIndex === gameIndex &&
                                    isEmpty(columnValue) &&
                                    ongoingAnimation === "idle" &&
                                    !isPropertyGuessed;

                                return (
                                    <React.Fragment key={`tile-column-${rowIndex}-${columnIndex}`}>
                                        {columnIndex === 2 || columnIndex === 5 ? (
                                            <span className="tw-text-3xl tw-font-medium tw-text-white">
                                                ,
                                            </span>
                                        ) : null}
                                        {renderTooltip(columnIndex, columnValue)}
                                        <Tile
                                            mode={checkTileMode(rowIndex, columnIndex, columnValue)}
                                            value={columnValue}
                                            onClick={() => inputRef.current.focus()}
                                            columnIndex={columnIndex}
                                            animationDelay={columnIndex * ANIMATION_DELAY_COEFFICIENT}
                                            onAnimationComplete={(animationName) => {
                                                if (animationName === "flip") {
                                                    if (columnIndex === length - 1) {
                                                        setOngoingAnimation("idle");
                                                        setDoneAnimate(true);
                                                    } else {
                                                        setOngoingAnimation("flip");
                                                    }
                                                }
                                            }}
                                            isFocused={isFocused}
                                            rowIndex={rowIndex}
                                            puzzleTooltipData={puzzleTooltipData}
                                            setPuzzleTooltipData={setPuzzleTooltipData}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </FocusProvider>

                        {!isPropertyGuessed && (
                            <BtnSubmit
                                rowValue={rowValue}
                                handleSubmit={handleSubmit}
                                rowIndex={rowIndex}
                                gameIndex={gameIndex}
                                ongoingAnimation={ongoingAnimation}
                            />
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        );
    }
);

Tile.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
    expand: PropTypes.bool.isRequired,
    gameIndex: PropTypes.number.isRequired,
    puzzleTooltipData: PropTypes.object.isRequired,
    setPuzzleTooltipData: PropTypes.func.isRequired,
    setOngoingAnimation: PropTypes.func,
    ongoingAnimation: PropTypes.string,
    rowIndex: PropTypes.number,
    value: PropTypes.string,
    checkTileMode: PropTypes.func,
    inputRef: PropTypes.object,
    isPropertyGuessed: PropTypes.bool,
};

export default TileRow;
