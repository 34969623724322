import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { filter, findIndex, some, split, toUpper, isEmpty, isNil, trim, toLower, without } from "lodash";
import TextTruncate from "react-text-truncate";
import { Tooltip } from "react-tooltip";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

import LeafletMap from "../LeafletMap";
import PropertyImageSwiper from "../property-image-swiper/property-image-swiper";
import SalesInfo from "../../../pages/daily-properties/sales-info/sales-info";
import SimilarProperties from "../../../pages/daily-properties/similar-properties/similar-properties";

import { PROPERTY_TYPE } from "../../../lang/property-type.lang";
import { TOOLTIP_TEXT } from "../../../lang/tooltip-text.lang";
import { isZero } from "../../../helpers/helpers";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { AutoTextSize } from "auto-text-size";

import "./property-info.scss";
import CustomButton from "../buttons/custom-button/custom-button";
import { RiExternalLinkLine } from "react-icons/ri";
import V3FlipSwiper from "../../../pages/LiveVariant/components/Flip/V3FlipSwiper";

/**
 * Component for displaying property information.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.property - The property data.
 * @param {Array} props.arrProperties - The array of properties.
 * @returns {JSX.Element} Property information component.
 */
const PropertyInfo = ({
    property,
    arrProperties,
    showMoreDetails = true,
    round,
    unBlurAll,
    index,
    showFullListing,
}) => {
    const [showMore, setShowMore] = useState(false);
    const [blurredBlocks, setBlurredBlocks] = useState([2, 3, 4, 5, 6, 7, 8]); // block 1 is always revealed initially
    const [hasUnblurredTheRound, setHasUnblurredTheRound] = useState(true);
    const { isAuthenticated } = useAuth0();
    const propertyAddress = split(property.street_address, ",")[0];
    const isPlusMinus = !isNil(round);
    const [preferredVersion] = useLocalStorage("live-variant-preferred-version");
    const isV3Flip = preferredVersion === "flip";
    const propertyType = PROPERTY_TYPE[property.property_type] || property.property_type;

    /**
     * Function to check and update property address if duplicates are found
     *
     * @param {Object} property - The property data.
     * @param {Array} arrProperties - The array of properties.
     * @returns {string} The unique property address.
     */
    const makeAddressUnique = (property, arrProperties) => {
        const normalizedStreetAddress = toLower(trim(propertyAddress));
        const normalizedNeighborhood = toLower(trim(property.neighborhood));

        if (!arrProperties) {
            return propertyAddress;
        }

        const otherProperties = filter(arrProperties, (p) => p.id !== property.id);
        const hasDuplicate = some(
            otherProperties,
            (p) =>
                split(toLower(trim(p.street_address)), ",")[0] === normalizedStreetAddress &&
                toLower(trim(p.neighborhood)) === normalizedNeighborhood
        );

        if (hasDuplicate) {
            const currentPropertyIndex = findIndex(arrProperties, { id: property.id });
            return `#${currentPropertyIndex + 1} ${propertyAddress}`;
        }

        return propertyAddress;
    };

    const uniquePropertyAddress = makeAddressUnique(property, arrProperties);

    const isCommonLotDimension =
        !isNil(property?.lot_dimensions) &&
        !isEmpty(property.lot_dimensions) &&
        toUpper(property.lot_dimensions) === "COMMON";

    const isUnknownLaundryFeature =
        !isNil(property?.laundry_features) &&
        !isEmpty(property.laundry_features) &&
        toUpper(property.laundry_features) === "UNKNOWN";

    const handleRevealInfo = (block) => {
        const expectedUnblurredBlocks = round - 1; // How many should have been unblurred
        const actualUnblurredBlocks = 7 - blurredBlocks.length; // How many are actually unblurred

        // Check if user has already unblurred info this round
        if (
            (!hasUnblurredTheRound || expectedUnblurredBlocks > actualUnblurredBlocks) &&
            isPlusMinus &&
            round !== 1
        ) {
            setBlurredBlocks((prev) => without(prev, block));
            setHasUnblurredTheRound(true); // Mark that info has been unblurred for this round
        }
    };

    // Track round changes and reset `hasUnblurredTheRound` when a new round starts
    useEffect(() => {
        if (round > 1) {
            setHasUnblurredTheRound(false);
        }
    }, [round]);

    useEffect(() => {
        if (unBlurAll) {
            setBlurredBlocks([]);
        }
    }, [unBlurAll]);

    return (
        <div className="position-relative">
            {isV3Flip ? (
                <V3FlipSwiper property={property} />
            ) : (
                <PropertyImageSwiper
                    property={property}
                    round={round}
                    blurredBlocks={blurredBlocks}
                    handleRevealInfo={handleRevealInfo}
                    hasUnblurredTheRound={hasUnblurredTheRound}
                    index={index}
                />
            )}

            <div
                className={clsx("property-details property-name tw-flex tw-flex-col tw-px-2", {
                    "!tw-py-0": isV3Flip,
                })}
            >
                <div className={clsx("property-details-key", { "tw-h-0": isV3Flip })}>
                    <div
                        className={clsx({
                            "property-details-blur": isPlusMinus && blurredBlocks.includes(4),
                            "tw-px-2": isV3Flip,
                        })}
                        onClick={() => handleRevealInfo(4)}
                    >
                        {!isV3Flip && (
                            <h2 id="address">
                                <>
                                    {uniquePropertyAddress} |{" "}
                                    {preferredVersion === "puzzle"
                                        ? `${property.neighborhood}, ${property.state}`
                                        : property.neighborhood}
                                </>
                            </h2>
                        )}

                        {showFullListing && (
                            <div className="tw-flex tw-justify-center">
                                <CustomButton
                                    className="tw-h-[26px] tw-flex tw-gap-1 tw-justify-center tw-items-center tw-text-[13px] tw-bg-[#FAB85F] tw-border-[1px] tw-border-solid tw-border-[#F69929] tw-rounded-[500px] tw-text-black"
                                    text="See Full Listing"
                                    rightIcon={<RiExternalLinkLine fontSize={15} />}
                                    handleClick={showFullListing}
                                />
                            </div>
                        )}
                    </div>

                    <div
                        className={clsx(
                            "main-info-row px-0",
                            { "property-details-blur": isPlusMinus && blurredBlocks.includes(5) },
                            {
                                "tw-relative tw-z-[1] tw-bottom-[72px]  md:tw tw-text-white tw-mb-[-70px] tw-bg-[#2E2E2E] tw-bg-opacity-[0.3]":
                                    isV3Flip,
                            }
                        )}
                        onClick={() => handleRevealInfo(5)}
                    >
                        {isV3Flip ? (
                            <>
                                <div
                                    className={clsx("main-info-column bed-column", {
                                        "!tw-flex-row": isV3Flip,
                                    })}
                                >
                                    <i class="tw-p-2 fa-solid fa-bed"></i>
                                    <span
                                        id="bedrooms"
                                        className={clsx("tw-pb-2", {
                                            "!tw-text-white !tw-pb-0 !tw-text-lg md:!tw-text-xl": isV3Flip,
                                        })}
                                    >
                                        {property.bedrooms}
                                    </span>
                                </div>
                                <div
                                    className={clsx("main-info-column bath-column", {
                                        "!tw-flex-row": isV3Flip,
                                    })}
                                >
                                    <i class="tw-p-2 fa-solid fa-bath"></i>
                                    <span
                                        id="bathrooms"
                                        className={clsx({
                                            "!tw-text-white !tw-text-lg md:!tw-text-xl": isV3Flip,
                                        })}
                                    >
                                        {property.bathrooms}
                                    </span>
                                </div>
                                <div
                                    className={clsx("main-info-column bed-column", {
                                        "!tw-flex-row": isV3Flip,
                                    })}
                                >
                                    <i class="tw-p-2 fa-solid fa-maximize"></i>
                                    <span
                                        className={clsx(!isZero(property.square_footage) && "has-tooltip", {
                                            "!tw-text-white !tw-text-lg md:!tw-text-xl": isV3Flip,
                                        })}
                                        id="square-footage"
                                        data-tooltip-id="square-footage"
                                    >
                                        {isZero(property.square_footage)
                                            ? "Unknown"
                                            : property.square_footage}{" "}
                                        {!isZero(property.square_footage) && "Sq.ft."}
                                    </span>
                                </div>{" "}
                                <div
                                    className={clsx("main-info-column type-column", {
                                        "!tw-flex-row": isV3Flip,
                                    })}
                                >
                                    <i class="tw-p-2 fa-solid fa-house-circle-exclamation"></i>
                                    <span
                                        id="property-type"
                                        className={clsx({
                                            "!tw-text-white !tw-text-lg md:!tw-text-xl": isV3Flip,
                                        })}
                                    >
                                        {isV3Flip
                                            ? propertyType.toLowerCase().includes("single family")
                                                ? "House"
                                                : propertyType
                                            : propertyType}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="main-info-column bed-column">
                                    <p>Beds</p>
                                    <span
                                        id="bedrooms"
                                        className={clsx("tw-pb-2", {
                                            "!tw-text-white !tw-pb-0 !tw-text-lg md:!tw-text-xl": isV3Flip,
                                        })}
                                    >
                                        {property.bedrooms}
                                    </span>
                                </div>
                                <div className="main-info-column bath-column">
                                    <p>Baths</p>
                                    <span
                                        id="bathrooms"
                                        className={clsx({
                                            "!tw-text-white !tw-text-lg md:!tw-text-xl": isV3Flip,
                                        })}
                                    >
                                        {property.bathrooms}
                                    </span>
                                </div>
                                <div className="main-info-column sq-column">
                                    <p>Sq. Ft</p>
                                    <span
                                        className={clsx(!isZero(property.square_footage) && "has-tooltip", {
                                            "!tw-text-white !tw-text-lg md:!tw-text-xl": isV3Flip,
                                        })}
                                        id="square-footage"
                                        data-tooltip-id="square-footage"
                                    >
                                        {isZero(property.square_footage)
                                            ? "Unknown"
                                            : property.square_footage}{" "}
                                        {isV3Flip && "Sq.ft."}
                                    </span>
                                    {!isZero(property.square_footage) &&
                                        isPlusMinus &&
                                        !blurredBlocks.includes(5) && (
                                            <Tooltip
                                                id="square-footage"
                                                anchorSelect="[data-tooltip-id='square-footage']"
                                                content={TOOLTIP_TEXT.SQUARE_FOOTAGE}
                                                place="top"
                                            />
                                        )}
                                </div>
                                <div className="main-info-column type-column">
                                    <p>Type</p>

                                    <span
                                        id="property-type"
                                        className={clsx({
                                            "!tw-text-white !tw-text-lg md:!tw-text-xl": isV3Flip,
                                        })}
                                    >
                                        {isV3Flip
                                            ? propertyType.toLowerCase().includes("single family")
                                                ? "House"
                                                : propertyType
                                            : propertyType}
                                    </span>
                                </div>
                            </>
                        )}
                        {isV3Flip && (
                            <h5
                                id="address"
                                className="tw-w-full tw-flex !tw-justify-center !tw-text-black tw-bg-white tw-px-1 md:tw-px-2 tw-py-2 md:tw-py-2.5"
                            >
                                <AutoTextSize maxFontSizePx={18} className="!tw-text-center">
                                    {uniquePropertyAddress} | {property.neighborhood}, {property.state}
                                </AutoTextSize>
                            </h5>
                        )}
                    </div>
                </div>
            </div>

            <div
                className={clsx("property-map-container tw-px-0", {
                    "property-details-blur": isPlusMinus && blurredBlocks.includes(4),
                    unclickable: isPlusMinus && hasUnblurredTheRound,
                })}
                onClick={() => handleRevealInfo(4)}
            >
                <LeafletMap property={property} mapOverlayed={!showMoreDetails} />
            </div>

            {showMoreDetails ? (
                <div className="property-details px-3">
                    <div className="property-details-key">
                        <div className="about-listing-row pb-3">
                            <div className="px-0">
                                <h3 className="mt-2">
                                    <strong className="subtitle">About this listing</strong>
                                </h3>
                                {!showMore ? (
                                    <div
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(7),
                                            unclickable: isPlusMinus && hasUnblurredTheRound,
                                        })}
                                        onClick={() => handleRevealInfo(7)}
                                    >
                                        <TextTruncate
                                            line={3}
                                            element="p"
                                            truncateText="…"
                                            text={property.public_remarks}
                                            containerClassName="truncated-public-remarks-container"
                                            textTruncateChild={
                                                <button
                                                    onClick={() => setShowMore(true)}
                                                    className="show-more-btn mt-2"
                                                >
                                                    Show More
                                                </button>
                                            }
                                        />
                                    </div>
                                ) : (
                                    <p>
                                        <span className="public-remarks">{property.public_remarks}</span>{" "}
                                        <span>
                                            <button
                                                onClick={() => setShowMore(false)}
                                                className="show-more-btn mt-2"
                                            >
                                                Show less
                                            </button>
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div
                            className={clsx({
                                "property-details-blur": isPlusMinus && blurredBlocks.includes(6),
                                unclickable: isPlusMinus && hasUnblurredTheRound,
                            })}
                            onClick={() => handleRevealInfo(6)}
                        >
                            <SimilarProperties property={property} isAuthenticated={isAuthenticated} />
                        </div>

                        <div id="additional-details">
                            <div className="pt-3 pb-4" onClick={() => handleRevealInfo(8)}>
                                <h3 className="mb-2">
                                    <strong className="subtitle">Additional Details</strong>
                                </h3>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Parking Spots:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >{`${property.parking_spots} ${property.parking_type}`}</p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Year Built:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {isZero(property.year_built) ? "Unknown" : property.year_built}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Exterior Features:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {property.exterior_features}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Interior Features:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {property.interior_features}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Heating:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {property.heating}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>AC:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        {property.cooling}
                                    </p>
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Laundry Features:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        <span
                                            className={clsx(isUnknownLaundryFeature && "has-tooltip")}
                                            data-tooltip-id="laundry-features"
                                        >
                                            {property.laundry_features}
                                        </span>
                                    </p>
                                    {isUnknownLaundryFeature && (
                                        <Tooltip
                                            id="laundry-features"
                                            anchorSelect="[data-tooltip-id='laundry-features']"
                                            content={TOOLTIP_TEXT.LAUNDRY_FEATURES}
                                            place="top"
                                        />
                                    )}
                                </div>
                                <div className="details-item d-flex justify-content-between">
                                    <span>Lot Dimensions:</span>
                                    <p
                                        className={clsx({
                                            "property-details-blur": isPlusMinus && blurredBlocks.includes(8),
                                        })}
                                    >
                                        <span
                                            className={clsx(isCommonLotDimension && "has-tooltip")}
                                            data-tooltip-id="lot-dimension"
                                        >
                                            {property.lot_dimensions}
                                        </span>
                                    </p>
                                    {isCommonLotDimension && (
                                        <Tooltip
                                            id="lot-dimension"
                                            anchorSelect="[data-tooltip-id='lot-dimension']"
                                            content={TOOLTIP_TEXT.LOT_DIMENSIONS}
                                            place="top-start"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <SalesInfo property_id={property.id} propertyStatus={property?.status_indicator} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

PropertyInfo.propTypes = {
    property: PropTypes.object.isRequired,
    arrProperties: PropTypes.array,
    showMoreDetails: PropTypes.bool,
    round: PropTypes.number,
    unBlurAll: PropTypes.bool,
    index: PropTypes.number,
    showFullListing: PropTypes.func,
};

export default PropertyInfo;
