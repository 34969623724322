import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

const heartVariants = {
    solid: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: { duration: 0.3 },
    },
    enlarge: {
        scale: 1.2, // Slightly increase size before meltdown
        transition: { duration: 0.2 },
    },
    shake: {
        x: [0, 2, -2, 2, -2, 0],
        transition: { duration: 0.6, repeat: 1, repeatType: "mirror" },
    },
    meltdown: {
        y: 30, // Move downward
        opacity: 0, // Fade out
        scale: 1.5, // Grow before disappearing
        transition: { duration: 0.8, ease: "easeInOut" },
    },
    outlined: {
        opacity: 1,
        y: 0,
        scale: 1, // Reset to normal
        transition: { duration: 0.5 },
    },
};

const AnimateShakeAndMeltdown = ({ play, children, outlinedChild, ...props }) => {
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [showOutline, setShowOutline] = useState(false);

    useEffect(() => {
        if (play) {
            // Delay animation by 5 seconds
            const startAnimationTimeout = setTimeout(() => {
                setShouldAnimate(true);
            }, 2000); // Fixed 5-second delay

            // Show outlined heart after meltdown animation completes
            const showOutlineTimeout = setTimeout(() => {
                setShowOutline(true);
            }, 3000); // 5s delay + 1s animation duration

            return () => {
                clearTimeout(startAnimationTimeout);
                clearTimeout(showOutlineTimeout);
            };
        }
    }, [play]);

    return (
        <div {...props}>
            <AnimatePresence>
                {!showOutline ? (
                    <motion.div
                        initial="solid"
                        animate={shouldAnimate ? ["enlarge", "shake", "meltdown"] : "solid"}
                        variants={heartVariants}
                    >
                        {children}
                    </motion.div>
                ) : (
                    <motion.div initial={{ opacity: 0 }} animate="outlined" variants={heartVariants}>
                        {outlinedChild}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

AnimateShakeAndMeltdown.propTypes = {
    play: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
    outlinedChild: PropTypes.element.isRequired,
};

export default AnimateShakeAndMeltdown;
